import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./Login.scss";
import TextField from "@mui/material/TextField";
import mosque from "../../assets/images/mosque.png";
import companyLogo from "../../assets/images/color-logo.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../hooks/useAuthHook";
import SnackbarCustom from "../../components/snackbar/SnackbarCustom";


function LoginPage() {
    const navigate = useNavigate();
    const { token } = useAuth();
    const { onLogin } = useAuth();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (token)
            navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [token])

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const displayAlertBox = message => {
        setOpenAlert(true)
        setAlertStatus('error')
        setAlertMessage(message)
    };

    const handleEmailChange = (e) => {
        let email = e.target.value;
        if (email === '') {
            displayAlertBox('Email Required!');
            return;
        }
        else if (validateEmail(email) === null) {
            displayAlertBox('Invalid Email!');
        }

        setEmail(email);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        (onLogin(email, password))
        if (localStorage.getItem('failedLogin'))
            displayAlertBox('The email or password you’ve entered is incorrect!')
        localStorage.removeItem('failedLogin');
    };

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <div className="login">
                <img className="moska" src={mosque} alt='mosque'></img>
                <div className="signup-form">
                    <form>
                        <img className="loginLogo" src={companyLogo} alt="" />

                        <TextField
                            className="signup-fields"
                            fullWidth
                            id="outlined-basic"
                            label="Email "
                            onChange={handleEmailChange}
                            value={email}
                            margin="dense"
                            variant="outlined"
                        />
                        <FormControl
                            margin="dense"
                            className="signup-fields"
                            fullWidth
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? "text" : "password"}
                                value={password}
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>

                        <button className="signup-button" onClick={handleSubmit} type="submit">
                            Login
                        </button>
                        <p className="already-member">
                            Not a member?{" "}
                            <Link className="alrmb" to={"/sign-up"}>
                                Sign up
                            </Link>{" "}
                        </p>
                    </form>
                </div>
            </div>

            <SnackbarCustom open={openAlert} alertStatus={alertStatus} handleClose={() => setOpenAlert(false)}>
                {alertMessage}
            </SnackbarCustom>
        </>
    );
}

export default LoginPage;
