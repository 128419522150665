import React from 'react';
import './Header.scss';
import companyLogo from '../../assets/images/color-logo.svg';
import logo from '../../assets/images/logo.jpg';
import { Link, useNavigate } from 'react-router-dom';
import borgir from '../../assets/images/menu.svg'
import { Divider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useAuth } from '../../hooks/useAuthHook';
export default function Header() {
  const { token, onLogout } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
    //dispatch(isUserLogged(false));
  }

  window.onscroll = function (event) {

    var scroll = window.pageYOffset;
    if (scroll > 20) {
      document.getElementById("header").style.backgroundColor = '#fff';
      document.getElementById("header").style.boxShadow = '0px 5px 15px -14px #000000';


      document.getElementById("sign-in").style.color = "#218796"
    } else {
      document.getElementById("header").style.backgroundColor = 'transparent';
      document.getElementById("header").style.boxShadow = '0px 0px 0px 0px #000000';

      document.getElementById("sign-in").style.color = "#75c19f"

    }


  }
  return (
    <div className='header' id='header'>
      <img onClick={borgiri} className='borgir' src={borgir} alt='borgir' />

      <div className='log-in-header'>
        <p className='header-language text-transition'>English</p>
        {!token ? (
          <>
            <Link className='text-transition' to={'/login'}> <span >Login</span></Link>
            <Link className='sign' to={'/sign-up'}> <span className='sign green-text' id='sign-in'>SIGN UP</span></Link>
          </>
        ) : (
          <div>
            <Avatar
              alt="Remy Sharp"
              src={logo}
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                navigate('/my-reservation');
              }
              }>My Reservation</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                navigate('/my-visa-application');
              }
              }>My Visa Application</MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}


        <div className='bottom-line'></div>
      </div>

      <div className='main-header'>
        <Link to="/" ><img src={companyLogo} className='header-logo' alt="" /></Link>
        <div className='header-links'>
          <Link onClick={borgiri} className='header-link text-transition' to="/hotels"><p>Booking</p></Link>
          <a className='header-link hajj' href="https://wayumrah.com" target="_blank" rel="noreferrer">Hajj</a>
          <Link onClick={borgiri} className='header-link dissapear text-transition' to="/sign-up"><p>Sign Up</p></Link>
          <Link onClick={borgiri} className=' text-transition' to="/visa-application"><button className='header-button' type="button">Apply for Visa</button></Link>
        </div>
      </div>
    </div>
  )
  function borgiri() {
    if (window.innerWidth < 768) {
      if (document.getElementById("header").style.height < "100") {
        document.getElementById("header").style.height = "100%";
      } else {
        document.getElementById("header").style.height = "10%";
      }
    }


  }
}
