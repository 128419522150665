import React, { useEffect, useState } from "react";
// import axios from "axios";
import Service from "../../services/general";
import { useNavigate, useLocation } from "react-router-dom";
import locationImage from "../../assets/images/location.svg";
import guestsImage from "../../assets/images/guests.svg";
import calendarImage from "../../assets/images/calendar.svg";
import hotelImage from "../../assets/images/hotel.svg";
import desertImage from "../../assets/images/desert.png";
import oceanImage from "../../assets/images/Rectangle.png";
import AlMashreq from "../../assets/images/Al-Mashreq.png";
import EliteSuites from "../../assets/images/Elite-suites.png";
import FourSeasons from "../../assets/images/Four-seasons.png";
import yjet from "../../assets/images/ratings.svg";
import ratings from "../../assets/images/rating.svg";
import bigDesert from "../../assets/images/big-desert.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import qabja from "../../assets/images/qabja.svg"
import cardloc from "../../assets/images/cardloc.svg"
import './Filter.scss'
import logoWithoutText from "../../assets/images/logo-without-text.svg";

function Hotelsdash({ show, hotelData, myClass }) {
  let navigate = useNavigate();
  const location = useLocation();
  const service = new Service();
  const [data, setData] = useState([]);
  const [hotelValue, setHotelValue] = useState("");
  const [priceValue, setPriceValue] = useState(999999);
  const [locationValue, setLocationValue] = useState("");
  const [distanceValue, setDistanceValue] = useState(999999);
  const hotelName = [
    { label: "Prof. Elwyn Lockman III" },
    { label: "Dr. Guido Tremblay" },
    { label: "Mr. Rudolph Kertzmann" },
    { label: "Lamont Beier" },
    { label: "Herminia Tillman" },
  ];
  const locations = [{ label: "Medina" }, { label: "Mecca" }, { label: "Riyadh" }];

  useEffect(() => {
    if (show && !hotelData) {
      searchHotels();
    } else if (hotelData) {
      setData(hotelData);
      navigate(location.state, {});
    }
  }, []);

  const handleHotelChange = (event) => {
    const value = event.target?.value || "";
    setHotelValue(value);
    event.preventDefault();
    searchHotels();

  };
  const handlePriceChange = (event) => {
    const value = event.target?.value || 999999;
    setPriceValue(value);

  };

  const handleLocationChange = (event) => {
    const value = event.target?.value || "";
    if (value === "Location") {
      setLocationValue("");
    } else {
      setLocationValue(value);
    }
  };

  const handleDistanceChange = (event) => {
    const value = event.target?.value || 999999;
    setDistanceValue(value);
    event.preventDefault();
    searchHotels();
  };

  const handleForm = (event) => {
    event.preventDefault();
    searchHotels();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchHotels = () => {
    service
      .searchHotels(hotelValue, locationValue, distanceValue)
      .then((res) => {
        setData(res.data.data);
        if (!show) {
          navigate("/hotels", {
            state: {
              hotelData: res.data.data,
            },
          });
        }
      })
      .catch((e) => console.log(e));
  };

  function HotelData() {
    return (
      <div className="hotelet-admin">
        <table class="customTable">
          <thead>
            <tr>
              <th>IMAGE</th>
              <th>HOTEL NAME</th>
              <th>DESCRIPTION</th>
              <th>ROOMS</th>
              <th>STATUS</th>
            </tr>
          </thead>
          {data &&
            data.slice(0, 10).map((dataHotel) => (
              <tbody>
                <tr>
                  <td>      {dataHotel.attributes.filename[0] ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img

                      src={dataHotel.attributes.filename[0].filename}
                      className=""
                    ></img>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                      className="card-img-top cardImg"
                    ></img>
                  )}</td>
                  <td>{dataHotel.attributes.name}</td>
                  <td> <p>{dataHotel.attributes.description.substring(0, 37)}...</p></td>
                  <td className="r-number">{dataHotel.attributes.rooms_number}</td>
                  <td className="edit-hotel"> <p>Edit Hotel</p></td>
                </tr>

              </tbody>
            ))}

        </table>


      </div>
    );
  }

  return (
    <div>

      {show && <HotelData data={data} />}
    </div>
  );
}

export default Hotelsdash;
