import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./Sign-Up.scss";
import TextField from "@mui/material/TextField";
import mosque from '../../assets/images/mosque.png'
import companyLogo from '../../assets/images/color-logo.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import SnackbarCustom from "../../components/snackbar/SnackbarCustom";
import { useAuth } from "../../hooks/useAuthHook";

export default function SignUp() {
    const navigate = useNavigate();
    const { token } = useAuth();
    const [values, setValues] = useState({
        password: '',
        confirmpassword: '',
        showPassword: false,
    });
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    useEffect(() => {
        if (token)
            navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const validateFullName = (name) => {
        var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
        if (!regName.test(name)) {
            return false;
        } else {
            return true;
        }
    };

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const displayAlertBox = message => {
        setOpenAlert(true)
        setAlertStatus('error')
        setAlertMessage(message)
    };

    const handleRegister = async (e) => {

        e.preventDefault();

        if (fullname === '') {
            displayAlertBox('Full Name Required!');
            return;
        } else if (validateFullName(fullname) === false) {
            displayAlertBox('Enter Full Name!');
            return;
        }
        else if (email === '') {
            displayAlertBox('Email Required!');
            return;
        }
        else if (validateEmail(email) === null) {
            displayAlertBox('Invalid Email!');
            return;
        }
        else if (values.password === '') {
            displayAlertBox('Password Required!');
            return;
        }
        else if (values.password.length < 6) {
            displayAlertBox('Password min 6 character!');
            return;
        }
        else if (values.confirmpassword === '') {
            displayAlertBox('Confirm Password Required!');
            return;
        }
        else if (values.confirmpassword !== values.password) {
            displayAlertBox('Confirm Password does not match!');
            return;
        }



        const params = {
            email: email,
            name: fullname,
            password: values.password,
            password_confirmation: values.confirmpassword
        }
        try {
            const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/register`, params)
            localStorage.setItem('token', data?.data?.data?.token)
            // console.log('data', data)

            setOpenAlert(true);
            setAlertStatus('success');
            setAlertMessage('Registered Successfully')
            const timer = setTimeout(() => {
                window.location.reload(true);
            }, 1000);


            return () => clearTimeout(timer);
        } catch (error) {
            console.log("Errorrrr: ", error?.response?.data.errors);
            setOpenAlert(true);
            setAlertStatus('error');
            setAlertMessage(error?.response?.data?.message ? `${error?.response?.data?.message}` : 'There is an error!')
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (

        <div className="sign-up">
            <div className="signup-form">
                <form onSubmit={handleRegister}>
                    <img src={companyLogo} alt="" />
                    <TextField
                        className="signup-fields"
                        fullWidth id="outlined-basic"
                        label="Full Name"
                        margin="dense"
                        variant="outlined"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                    />
                    <TextField
                        className="signup-fields"
                        fullWidth id="outlined-basic"
                        label="Email"
                        margin="dense"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormControl margin="dense" className="signup-fields" fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl margin="dense" className="signup-fields" fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.confirmpassword}
                            onChange={handleChange('confirmpassword')}
                            endAdornment={

                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>

                            }
                            label="Confirm Password"
                        />
                    </FormControl>
                    <button className="signup-button" type="submit">Sign up</button>
                    <p className="already-member">Already a  member? <Link className="alrmb" to={"/login"}>Login</Link> </p>
                </form>
            </div>


            <img src={mosque} className="sign-mosque" alt="" />
            <SnackbarCustom open={openAlert} alertStatus={alertStatus} handleClose={() => setOpenAlert(false)}>
                {alertMessage}
            </SnackbarCustom>
        </div>
    )
}