import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import locationImage from "../../assets/images/location.svg";
import hotelImage from "../../assets/images/hotel.svg";
import './showHotels.scss'
import { axiosConfig } from "../../services/axios";

function ShowHotels() {
    const [data, setData] = useState([]);

    useEffect(() => {
        findRandomHotels();
    }, []);

    const findRandomHotels = async () => {
        await axiosConfig.get(`/v1/hotels?per_page=3&order=rand`).then(res => {
            setData(res?.data?.data)
        })
    }

    return (
        <div className="hotels-wrapper">
            {data.length > 0 &&
                data?.map((dataHotel) => (
                    <Link
                        key={dataHotel?.id}
                        className="hotel-home"
                        to={`/hotel/${dataHotel?.id}`}
                    >

                        {dataHotel.attributes.filename[0] ? (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img

                                src={dataHotel.attributes.filename[0].filename}
                                className="img-home"
                            ></img>
                        ) : (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                                className="card-img-top img-home"
                            ></img>
                        )}

                        <p className="hotel-name">{dataHotel.attributes.name}</p>
                        <div className="hotel-info-wrap">
                            <img src={locationImage} className="small-logo" />
                            <p className="hotel-info">{dataHotel.attributes.location}</p>
                        </div>
                        <div className="hotel-info-wrap">
                            <img src={hotelImage} className="small-logo" />
                            <p className="hotel-info">{dataHotel.attributes.distance_from_kaaba}</p>
                        </div>
                    </Link>

                ))}
        </div>
    );
}

export default ShowHotels;
