import {
    GET_RESERVATION_REQUEST,
    GET_RESERVATION_SUCCESS,
    GET_RESERVATION_FAIL,
    GET_VISA_APPLICATION_REQUEST,
    GET_VISA_APPLICATION_SUCCESS,
    GET_VISA_APPLICATION_FAIL
} from '../constants/reservation';
import { axiosAuth } from '../../services/axios';

export const getMyReservation = () => async (dispatch) => {
    try {
        dispatch({ type: GET_RESERVATION_REQUEST });
        const data = await axiosAuth.get(`/v1/hotel_reservation`);
        dispatch({
            type: GET_RESERVATION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_RESERVATION_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}

export const getMyVisaApplication = () => async (dispatch) => {
    try {
        dispatch({ type: GET_VISA_APPLICATION_REQUEST });
        const data = await axiosAuth.get(`/v1/visa_application`);
        dispatch({
            type: GET_VISA_APPLICATION_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_VISA_APPLICATION_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}