import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyVisaApplication } from '../../store/Actions/reservationAction';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';
import './MyVisaApplication.scss';
import moment from 'moment';

const MyVisaApplication = () => {
    const dispatch = useDispatch();
    const { loading, data } = useSelector(state => state?.myVisaApplication)

    useEffect(() => {
        dispatch(getMyVisaApplication())
    }, [dispatch])

    if (loading) {
        return (
            <div className='loading'>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color='success' />
                </Box>
            </div>
        )
    }

    return (
        <div id='my-visa-application'>
            {data && data?.data?.data?.length > 0 ? (
                <>
                    <Typography variant='h5' className='my-20'>My Visa Application</Typography>
                    <TableContainer component={Paper} style={{ display: 'flex', marginBottom: '30px' }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableCell className='white' align="right">ID</TableCell>
                                    <TableCell className='white' align="right">User</TableCell>
                                    <TableCell className='white' align="right">Address</TableCell>
                                    <TableCell className='white' align="right">Zip</TableCell>
                                    <TableCell className='white' align="right">City</TableCell>
                                    <TableCell className='white' align="right">Country</TableCell>
                                    <TableCell className='white' align="right">Passport Issue</TableCell>
                                    <TableCell className='white' align="right">Passport Expiry</TableCell>
                                    <TableCell className='white' align="right">Passport No</TableCell>
                                    <TableCell className='white' align="right">Passport Type</TableCell>
                                    <TableCell className='white' align="right">Hotel Name</TableCell>
                                    <TableCell className='white' align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.data?.map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell align="right">{row?.id}</TableCell>
                                            <TableCell align="right">{row?.attributes?.user?.name}</TableCell>
                                            <TableCell align="right">{row?.attributes?.address}</TableCell>
                                            <TableCell align="right">{row?.attributes?.zip}</TableCell>
                                            <TableCell align="right">{row?.attributes?.city}</TableCell>
                                            <TableCell align="right">{row?.attributes?.country}</TableCell>
                                            <TableCell align="right">{moment(row?.attributes?.passport_issue_date).format('L')}</TableCell>
                                            <TableCell align="right">{moment(row?.attributes?.passport_expiry_date).format('L')}</TableCell>
                                            <TableCell align="right">{row?.attributes?.passport_no}</TableCell>
                                            <TableCell align="right">{row?.attributes?.passport_type}</TableCell>
                                            <TableCell align="right">{row?.attributes?.hotel_name}</TableCell>
                                            <TableCell align="right">{row?.attributes?.status}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) :
                <>
                    <Typography variant='h5' className='my-20'>My Visa Application</Typography>
                    <p>Empty!</p>
                </>
            }
        </div>
    )
}

export default MyVisaApplication