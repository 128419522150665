export const SET_SEARCH_HOTEL_PARAMS = 'SET_SEARCH_HOTEL_PARAMS';

export const SEARCH_HOTEL_REQUEST = 'SEARCH_HOTEL_REQUEST';
export const SEARCH_HOTEL_SUCCESS = 'SEARCH_HOTEL_SUCCESS';
export const SEARCH_HOTEL_FAIL = 'SEARCH_HOTEL_FAIL';

export const SEARCH_HOTEL_BY_ID_REQUEST = 'SEARCH_HOTEL_BY_ID_REQUEST';
export const SEARCH_HOTEL_BY_ID_SUCCESS = 'SEARCH_HOTEL_BY_ID_SUCCESS';
export const SEARCH_HOTEL_BY_ID_FAIL = 'SEARCH_HOTEL_BY_ID_FAIL';

export const SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST = 'SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST';
export const SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS = 'SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS';
export const SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL = 'SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL';

export const CREATE_RESERVATION_REQUEST = 'CREATE_RESERVATION_REQUEST';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAIL = 'CREATE_RESERVATION_FAIL';

export const GET_ALL_HOTELS_REQUEST = 'GET_ALL_HOTELS_REQUEST';
export const GET_ALL_HOTELS_SUCCESS = 'GET_ALL_HOTELS_SUCCESS';
export const GET_ALL_HOTELS_FAIL = 'GET_ALL_HOTELS_FAIL';
;