import React from 'react'
import { Box, CircularProgress } from '@mui/material';
import './Loading.scss'

const Loading = () => {
    return (
        <div className='loading'>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress color='success' />
            </Box>
        </div>
    )
}

export default Loading