import React from 'react'
import './AdminDashboard.scss'
import Service from '../../services/general'
import homeDashboard from "../../assets/images/ic_home.svg"
import homeInvoice from "../../assets/images/ic_invoice.svg"
import homeUsers from "../../assets/images/ic_users.svg"
import homeHotel from "../../assets/images/ic_hotels.svg"
import homeReservation from "../../assets/images/ic_reservations.svg"
import Filter from '../../components/Filter/Filter'
import { useNavigate, useLocation } from "react-router-dom";
import locationImage from "../../assets/images/location.svg";
import guestsImage from "../../assets/images/guests.svg";
import calendarImage from "../../assets/images/calendar.svg";
import hotelImage from "../../assets/images/hotel.svg";
import desertImage from "../../assets/images/desert.png";
import oceanImage from "../../assets/images/Rectangle.png";
import AlMashreq from "../../assets/images/Al-Mashreq.png";
import EliteSuites from "../../assets/images/Elite-suites.png";
import FourSeasons from "../../assets/images/Four-seasons.png";
import yjet from "../../assets/images/ratings.svg";
import ratings from "../../assets/images/rating.svg";
import bigDesert from "../../assets/images/big-desert.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import qabja from "../../assets/images/qabja.svg"
import cardloc from "../../assets/images/cardloc.svg"
import logoWithoutText from "../../assets/images/logo-without-text.svg";
import AddHotel from '../../components/AddHotel/AddHotel'
import { useState } from 'react'
import Hotelsdash from '../../components/Filter/hotelsdash'
import { useEffect } from 'react'
function AdminDashboard(show, hotelData) {
  let navigate = useNavigate();
  const [hotelValue, setHotelValue] = useState("");
  const [priceValue, setPriceValue] = useState(999999);
  const [locationValue, setLocationValue] = useState("");
  const [distanceValue, setDistanceValue] = useState(999999);
  const location = useLocation();
  const service = new Service();
  const [data, setData] = useState([]);


  useEffect(() => {
    if (show && !hotelData) {
      searchHotels();
    } else if (hotelData) {
      setData(hotelData);
      navigate(location.state, {});
    }
  }, []);
  const searchHotels = () => {
    service
      .searchHotels(hotelValue, locationValue, distanceValue)
      .then((res) => {
        setData(res.data.data);
        if (!show) {
          navigate("/hotels", {
            state: {
              hotelData: res.data.data,
            },
          });
        }
      })
      .catch((e) => console.log(e));
  };
  function clickHija() {
    document.getElementById("add-hotel").style.display = "none";
    document.getElementById("hija").style.display = "none"
  }
  function clickEditHotel() {
    document.getElementById("add-hotel").style.display = "block";
    document.getElementById("hija").style.display = "block"
  }
  return (
    <div className='admin-dashboard'>
      <div id='add-hotel' className='add-hotel'>
        <AddHotel />
      </div>
      <div onClick={clickHija} id="hija" className='hija'></div>
      <div show={true} className='admin-sidebar'>
        <div className='sidestick'>
          <div className='sidebar-cat  first-cat active-cat'>
            <img src={homeDashboard} alt="" />
            <p className='green-sidebar'>Home</p>
          </div>
          <div className='sidebar-cat'>
            <img src={homeInvoice} alt="" />
            <p>Invoices</p>
          </div>
          <div className='sidebar-cat'>
            <img src={homeUsers} alt="" />
            <p>Visa Applications</p>
          </div>
          <div className='sidebar-cat'>
            <img src={homeHotel} alt="" />
            <p>Hotels</p>
          </div>
          <div className='sidebar-cat'>
            <img src={homeReservation} alt="" />
            <p className='reservations-header'>Hotel Reservations</p>
          </div>
        </div>
      </div>
      <div className='admin-content'>
        <div className='reservo'>
          <p>Hotels</p>
          <button onClick={clickEditHotel} className='add-hotels'>
            Add Hotel
          </button>
        </div>
        <Hotelsdash
          show={true}
          hotelData={
            location?.state?.hotelData ? location?.state?.hotelData : null
          }
          myClass='filter-filter'
          className="filter-filter"
        />
      </div>


    </div>
  )
}

export default AdminDashboard