import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Visa-application.scss";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import arabianShield from '../../assets/images/Arabian-Shield-fb.png'
import Autocomplete from '@mui/material/Autocomplete';
import goBack from '../../assets/images/arrow-left.svg'
import Wala from '../../assets/images/wala.png'
import Tawuniya from '../../assets/images/tawuniya.png'
import Bupa from '../../assets/images/bupa.png'
import Medgulf from '../../assets/images/medgulf.png'
import gulfUnion from '../../assets/images/gulf-union.png'
// import Pdfpopup from "../../components/pdf-PopUp/Pdf-popup";
import { axiosAuth } from "../../services/axios";
import moment from 'moment';
import { countries } from "../../services/staticData";
import { getAllHotels } from "../../store/Actions/searchHotelsAction";
import { useDispatch, useSelector } from "react-redux";
import SnackbarCustom from '../../components/snackbar/SnackbarCustom'
import { Typography } from "@mui/material";

export default function Visa() {
    const dispatch = useDispatch();
    const user = useSelector(state => state?.user?.data?.data?.data)
    const allHotels = useSelector(state => state?.allHotels?.payload?.data);

    const [passaportType, setPassaportType] = useState(null);
    const [chooseCountry, setChooseCountry] = useState(null);

    const [personalPhoto, setPersonalPhoto] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState(null);
    const [martialStatus, setMartialStatus] = useState(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthday, setBirthday] = useState('');
    const [profession, setProfession] = useState(null);

    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [address, setAddress] = useState('');

    const [passportPhoto, setPassportPhoto] = useState(null);
    const [passportNo, setPassportNo] = useState('');
    const [passportIssue, setPassportIssue] = useState('');
    const [issueDate, setIssueDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [expectedDateArrival, setExpectedDateArrival] = useState('');
    const [expectedDateDepartment, setExpectedDateDepartment] = useState('');
    const [purposeOfVisit, setPurposeOfVisit] = useState(null);

    const [staying, setStaying] = useState('relative');
    const [nameOfHotel, setNameOfHotel] = useState('');
    const [medicalInsurance, setMedicalInsurance] = useState('');
    const [residentialEmail, setResidentialEmail] = useState('');
    const [residentialPhone, setResidentialPhone] = useState('');
    const [hotelName, setHotelName] = useState('');

    const [displayBlock, setDisplayBlock] = useState(0);
    const [box, setBox] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [sideBarTitle, setSideBarTitle] = useState('Visa Application');

    useEffect(() => {
        dispatch(getAllHotels())
    }, [dispatch])

    const handleClearInputs = () => {
        setPassaportType(null);
        setChooseCountry(null);
        setPersonalPhoto('');
        setFirstName('');
        setLastName('');
        setGender(null);
        setMartialStatus(null);
        setEmail('');
        setPhone('');
        setBirthday('');
        setProfession(null);
        setCountry('');
        setCity('');
        setZip('');
        setAddress('');
        setPassportPhoto(null);
        setPassportNo('');
        setPassportIssue('');
        setIssueDate('');
        setExpiryDate('');
        setExpectedDateArrival('');
        setExpectedDateDepartment('');
        setPurposeOfVisit(null);
        setStaying(null);
        setNameOfHotel('');
        setMedicalInsurance('');
        setResidentialEmail('');
        setResidentialPhone('');
        setHotelName('');
    }

    const handleSubmitVisa = async (e) => {
        e.preventDefault();
        const params = {
            first_name: firstName,
            last_name: lastName,
            gender: gender,
            martial_status: martialStatus,
            email: email,
            phone_number: phone,
            birthday: moment(birthday).format('YYYY-MM-DD'),
            passport_type: passaportType,
            country: country,
            profession: profession,
            city: city,
            zip: zip,
            address: address,
            passport_issue_place: passportIssue,
            passport_issue_date: moment(issueDate).format('YYYY.MM.DD'),
            passport_expiry_date: moment(expiryDate).format('YYYY.MM.DD'),
            expected_date_arrival: moment(expectedDateArrival).format('YYYY-MM-DD'),
            expected_date_department: moment(expectedDateDepartment).format('YYYY-MM-DD'),
            purpose_visit: purposeOfVisit,
            staying: staying,
            hotelName: hotelName,
            passport_no: passportNo,
            image: personalPhoto,
            image_vacation_evidence: passportPhoto,
            country_nationality: chooseCountry?.label,
            medical_insurance: medicalInsurance,
            hotel_id: nameOfHotel?.id,
            residential_email: residentialEmail,
            residential_phone: residentialPhone
        }

        try {
            await axiosAuth.post('/v1/applications', params).then(res => {
                // console.log('res', res)
                handleClearInputs();
                setDisplayBlock(0);
                setOpenAlert(true);
                setAlertStatus('success')
                setAlertMessage('Applied successfully')
                setSideBarTitle('Visa Application');
            })
        } catch (error) {
            setOpenAlert(true);
            setAlertStatus('error')
            setAlertMessage(`${error?.response?.data?.message}`)
        }
    }

    const handlePersonalImage = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('image', e?.target?.files?.[0])

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const { data } = await axiosAuth.post('/v1/upload_image', formData, config)
            setPersonalPhoto(data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const handlePassportImage = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('image', e?.target?.files?.[0])

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const { data } = await axiosAuth.post('/v1/upload_image', formData, config)
            setPassportPhoto(data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const displayAlertBox = message => {
        setOpenAlert(true)
        setAlertStatus('error')
        setAlertMessage(message)
    };

    // Validation 

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleStepOneContinue = () => {
        if (passaportType === null) {
            displayAlertBox('Passport Type Required!')
        } else if (chooseCountry === null) {
            displayAlertBox('Choose a country Required!');
        }

        if (passaportType !== null && chooseCountry !== null) {
            setDisplayBlock(1);
            setSideBarTitle('Personal Information');
        }
    }

    const handleStepTwoContinue = () => {
        if (personalPhoto === '') {
            displayAlertBox('Personal Image Required!')
        }
        else if (firstName === '') {
            displayAlertBox('First Name Required!')
        }
        else if (lastName === '') {
            displayAlertBox('Last Name Required!')
        }
        else if (gender === null) {
            displayAlertBox('Gender Required!')
        }
        else if (martialStatus === null) {
            displayAlertBox('Martial Status  Required!')
        }
        else if (email === '') {
            displayAlertBox('Email Required!');
        }
        else if (validateEmail(email) === null) {
            displayAlertBox('Invalid Email!');
        }
        else if (phone === '') {
            displayAlertBox('Phone Number Required!')
        }
        else if (birthday === '') {
            displayAlertBox('Birthday Required!')
        }
        else if (profession === null) {
            displayAlertBox('Profession Required!')
        }

        if (personalPhoto !== ''
            && firstName !== ''
            && lastName !== ''
            && gender !== null
            && martialStatus !== null
            && validateEmail(email) !== null
            && phone !== ''
            && birthday !== ''
            && profession !== null) {
            setDisplayBlock(2);
            setSideBarTitle('Current Residence Address');
        }
    }

    const handleStepThreeContinue = () => {
        if (country === '') {
            displayAlertBox('Country Required!')
        }
        else if (city === '') {
            displayAlertBox('City Required!')
        }
        else if (zip === '') {
            displayAlertBox('Zip / Postal Code Required!')
        }
        else if (address === '') {
            displayAlertBox('Address type Required!')
        }

        if (personalPhoto !== ''
            && country !== ''
            && city !== ''
            && zip !== ''
            && address !== '') {
            setDisplayBlock(3);
            setSideBarTitle('Passport / Traveler');
        }
    }

    const handleStepFourContinue = () => {
        if (passportPhoto === null) {
            displayAlertBox('Passport Photo Required!')
        }
        else if (passportNo === '') {
            displayAlertBox('Passport No Required!')
        }
        else if (passportIssue === '') {
            displayAlertBox('Passport Issue Place Required!')
        }
        else if (issueDate === '') {
            displayAlertBox('Passport Issue Date Required!')
        }
        else if (expiryDate === '') {
            displayAlertBox('Passport Expiry Date Required!')
        }
        else if (expectedDateArrival === '') {
            displayAlertBox('Expected Date of Arrival Required!')
        }
        else if (expectedDateDepartment === '') {
            displayAlertBox('Expected Date of Department Required!')
        }
        else if (purposeOfVisit === null) {
            displayAlertBox('Purpose of Visit Required!')
        }

        if (passportPhoto !== null
            && passportNo !== ''
            && passportIssue !== ''
            && issueDate !== ''
            && expiryDate !== ''
            && expectedDateArrival !== ''
            && expectedDateDepartment !== ''
            && purposeOfVisit !== null) {
            setDisplayBlock(4);
            setSideBarTitle('Residence address in Saudi Arabia');
        }
    }

    const handleStepFiveContinue = () => {
        if (staying === 'relative') {
            if (nameOfHotel.id === '') {
                displayAlertBox('Name of Hotel Required!')
            }
            else if (residentialEmail === '') {
                displayAlertBox('Residential Email Required!')
            }
            else if (validateEmail(residentialEmail) === null) {
                displayAlertBox('Invalid Residential Email!');
            }
            else if (residentialPhone === '') {
                displayAlertBox('Residential phone Required!')
            }
        } else if (staying === 'commercial') {
            if (hotelName === '') {
                displayAlertBox('Name of Hotel Required!')
            }
        }

        if ((staying === 'relative'
            && nameOfHotel.id !== null
            && validateEmail(residentialEmail) !== null
            && residentialPhone !== '')
            || (staying === 'commercial' && hotelName !== '')) {
            setDisplayBlock(5);
            setSideBarTitle('Medical Insurance');
        }
    }

    const handleStepSixContinue = () => {

        if (box === null) {
            displayAlertBox('Medical Insurance Required!')
        }
        if (box !== null){
            setDisplayBlock(6);
            setSideBarTitle('Terms & Conditions');
        }
    }

    return (
        <div className="visa-application">
            <div className="visa-leftmenu" id="visa-leftmenu">
                <p className="visa-header" id="first-step-change">{sideBarTitle}</p>
                <hr />
                {/* <Typography variant="caption">Username:</Typography>
                <Typography variant="h6">{user?.name}</Typography> */}

                {(firstName && lastName) &&
                    <div className="personal_info">
                        <Typography variant="caption">Username:</Typography>
                        <Typography variant="h6">{firstName} {lastName}</Typography>
                    </div>
                }

                {chooseCountry &&
                    <div className="personal_info">
                        <Typography variant="caption">Country of Nationality:</Typography>
                        <Typography variant="h6"> {chooseCountry.label}</Typography>
                    </div>
                }

                {passaportType &&
                    <div className="personal_info">
                        <Typography variant="caption">Passport Type:</Typography>
                        <Typography variant="h6">{capitalizeFirst(passaportType)}</Typography>
                    </div>
                }
            </div>

            <div className="visa-steps" id="visa-steps">
                <form action='' onSubmit={(e) => handleSubmitVisa(e)}>
                    <div className={displayBlock === 0 ? "steps block" : "steps no-block"}>

                        <Box className="form-control" sx={{ minWidth: 120 }}>
                            <FormControl id="passport" fullWidth className="input-visa">
                                <InputLabel id="demo-simple-select-label" variant="outlined">
                                    Passport Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Passport Type"
                                    value={passaportType}
                                    onChange={(e) => setPassaportType(e.target.value)}
                                >
                                    <MenuItem value={"diplomatic"}>
                                        Diplomatic Passport
                                    </MenuItem>
                                    <MenuItem value={"regular"}>
                                        Regular Passport
                                    </MenuItem>
                                    <MenuItem value={"special"}>
                                        Special Passport
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Autocomplete
                            fullWidth
                            className="input-visaa"
                            id="country-select-demo"
                            sx={{ width: 300 }}
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            value={chooseCountry}
                            onChange={(event, newValue) => {
                                // console.log('cont: ', newValue);
                                setChooseCountry(newValue);
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.label} ({option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a country"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <p className="not-eligible">
                            In case your nationality country is not eligible for Way Umrah
                            visa, please contact the nearest embassy of Saudi Arabia
                        </p>
                        <button
                            // onClick={() => setDisplayBlock(1)} 
                            onClick={handleStepOneContinue}
                            type="button"
                            className="step-continue">
                            Continue
                        </button>


                    </div>{" "}

                    {/* first step ends here */}

                    <div className={displayBlock === 1 ? "steps block" : "steps no-block"}>
                        {/* <button type='button' id='image-upload' onClick={getFile}> <span class/Name='file-text'>Upload Photo of yourself</span><br/> Upload Photo</button> */}
                        {/* <TextField type="file" accept="image/*" id="getFile" onChange={(e) => handlePersonalImage(e)} /> */}
                        <input style={{ border: "1px solid #BFBFBF", borderRadius: "5px" }} className="css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" type="file" accept="image/*" id="getFile" onChange={(e) => handlePersonalImage(e)} />
                        <p className="file-text">
                            Please upload image of dimensions 200 x 200 px. Personal Image
                            Size must be from 5 to 100 Kb. Allowed picture file types are
                            .jpg, .jpeg, .png, .gif, .bmp
                        </p>
                        <div className="basic-visa-wrap">
                            <TextField
                                helperText="Please make sure to enter the names exactly mentioned on your passport in English Only"
                                className="input-visa"
                                label="First Name"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <TextField
                                helperText="Please make sure to enter the names exactly mentioned on your passport in English Only"
                                className="input-visa"
                                label="Last Name"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <FormControl className="input-visa">
                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Gender"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="input-visa">
                                <InputLabel id="demo-simple-select-label">
                                    Marital Status
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Maritual Status"
                                    value={martialStatus}
                                    onChange={(e) => setMartialStatus(e.target.value)}
                                >
                                    <MenuItem value={"Married"}>Married</MenuItem>
                                    <MenuItem value={"Single"}>Single</MenuItem>
                                    <MenuItem value={"In a Relationship"}>
                                        In a Relationship
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                type="email"
                                helperText=""
                                className="input-visa"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                helperText=""
                                type="number"
                                className="input-visa"
                                label="Phone Number"
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <TextField
                                className="input-visa"
                                id="date"
                                label="Birthday"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={birthday}
                                onChange={(e) => setBirthday(e.target.value)}
                            />
                            <FormControl className="input-visa">
                                <InputLabel id="demo-simple-select-label">
                                    Profession
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Profession Type"
                                    value={profession}
                                    onChange={(e) => setProfession(e?.target?.value)}
                                >
                                    <MenuItem value={"Programmer"}>Programmer</MenuItem>
                                    <MenuItem value={"Designer"}>Designer</MenuItem>
                                    <MenuItem value={"IT Technician"}>IT Technician</MenuItem>
                                    <MenuItem value={"Social Media Manager"}>
                                        Social Media Manager
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <button
                                type="button"
                                // onClick={() => setDisplayBlock(2)}
                                onClick={handleStepTwoContinue}
                                className="step-continue"
                            >
                                Continue
                            </button>

                        </div>
                        <p onClick={() => { setDisplayBlock(0); setSideBarTitle('Visa Application') }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>
                    <div className={displayBlock === 2 ? "steps block" : "steps no-block"} >
                        <TextField
                            helperText=""
                            type="text"
                            className="input-visa"
                            label="Country"
                            variant="outlined"
                            value={country}
                            onChange={(e) => setCountry(e?.target?.value)}
                        />
                        <TextField
                            helperText=""
                            type="text"
                            className="input-visa"
                            label="City"
                            variant="outlined"
                            value={city}
                            onChange={(e) => setCity(e?.target?.value)}
                        />
                        <TextField
                            helperText=""
                            type="text"
                            className="input-visa"
                            label="Zip/Postal Code"
                            variant="outlined"
                            value={zip}
                            onChange={(e) => setZip(e?.target?.value)}
                        />
                        <TextField
                            helperText=""
                            type="text"
                            className="input-visa"
                            label="Address"
                            variant="outlined"
                            value={address}
                            onChange={(e) => setAddress(e?.target?.value)}
                        />
                        <button
                            type="button"
                            // onClick={() => setDisplayBlock(3)} 
                            onClick={handleStepThreeContinue}
                            className="step-continue">
                            Continue
                        </button>
                        <p onClick={() => { setDisplayBlock(1); setSideBarTitle('Personal Information') }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>

                    {/* second step stops here */}
                    <div className={displayBlock === 3 ? "steps block" : "steps no-block"}>
                        {/* <TextField type="file" accept="image/*" id="getFile" onChange={(e) => handlePassportImage(e)} /> */}

                        <input style={{ border: "1px solid #BFBFBF", borderRadius: "5px" }} className="css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input" type="file" accept="image/*" id="getFile" onChange={(e) => handlePassportImage(e)} />

                        <p className="file-text">
                            EVIDENCE OF VACCINATION!
                        </p>
                        <div className="basic-visa-wrap">
                            <TextField
                                helperText=""
                                type="number"
                                className="input-visa"
                                label="Passport No"
                                variant="outlined"
                                value={passportNo}
                                onChange={(e) => setPassportNo(e.target.value)}
                            />
                            <TextField
                                helperText=""
                                className="input-visa"
                                label="Passport Issue Place (County or City)"
                                variant="outlined"
                                value={passportIssue}
                                onChange={(e) => setPassportIssue(e.target.value)}
                            />
                            <TextField
                                className="input-visa"
                                id="date"
                                label="Passport Issue Date"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={issueDate}
                                onChange={(e) => setIssueDate(e.target.value)}
                            />
                            <TextField
                                className="input-visa"
                                id="date"
                                label="Passport Expiry Date"
                                type="date"
                                helperText="Passport must be valid at least 6 months from the Visa application at a time."
                                defaultValue="2017-05-24"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={expiryDate}
                                onChange={(e) => setExpiryDate(e.target.value)}
                            />
                            <TextField
                                className="input-visa"
                                id="date"
                                label="Expected date of arrival"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={expectedDateArrival}
                                onChange={(e) => setExpectedDateArrival(e.target.value)}
                            />
                            <TextField
                                className="input-visa"
                                id="date"
                                label="Expected date of department"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={expectedDateDepartment}
                                onChange={(e) => setExpectedDateDepartment(e.target.value)}
                            />
                        </div>
                        <FormControl className="input-visa">
                            <InputLabel id="demo-simple-select-label">
                                Purpose of Visit
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Purpose of Visit"
                                value={purposeOfVisit}
                                onChange={(e) => setPurposeOfVisit(e.target.value)}
                            >
                                <MenuItem value={"event"}>Event</MenuItem>
                                <MenuItem value={"family"}>Family & Relatives</MenuItem>
                                <MenuItem value={"leisure"}>Leisure</MenuItem>
                                <MenuItem value={"umrah"}>
                                    Umrah
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <button
                            type="button"
                            // onClick={() => setDisplayBlock(4)} 
                            onClick={handleStepFourContinue}
                            className="step-continue">
                            Continue
                        </button>
                        <p onClick={() => { setDisplayBlock(2); setSideBarTitle('Current Residence Address'); }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>  {/* Third step stops here */}

                    <div className={displayBlock === 4 ? "steps block" : "steps no-block"}>
                        {/* <FormControl className="input-visaa">
                            <InputLabel id="demo-simple-select-label">
                                Where will you be staying?
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Where will you be staying?"
                                value={staying}
                                onChange={(e) => setStaying(e.target.value)}
                            >
                                <MenuItem value={"relative"}>Residential or relative</MenuItem>
                                <MenuItem value={"commercial"}>Commercial Accomodation</MenuItem>

                            </Select>
                        </FormControl> */}
                        {/* <TextField
                            helperText=""
                            className="input-visa"
                            label="Name of the hotel"
                            variant="outlined"
                            value={nameOfHotel}
                            onChange={(e) => setNameOfHotel(e.target.value)}
                        /> */}

                        <input onClick={() => { setStaying('relative'); setHotelName(''); }} className="residential-button" type="radio" name="relative-commercial" id="" /> <span>Residental or Relative</span>

                        <input onClick={() => setStaying('commercial')} className="commercial-button" type="radio" name="relative-commercial" id="" style={{ marginBottom: "20px", paddingLeft: "20px" }} /> <span>Commercial Accomodation</span>


                        {staying === 'relative' ?
                            <>
                                {allHotels?.length > 0 && (
                                    <Autocomplete

                                        fullWidth
                                        className="input-visa"
                                        id="hotel_select"
                                        sx={{ marginTop: 2 }}
                                        options={allHotels}
                                        autoHighlight
                                        getOptionLabel={(option) => option?.name || ''}
                                        value={nameOfHotel}
                                        onChange={(event, newValue) => {
                                            setNameOfHotel(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Choose Hotel"
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                )}
                                <TextField
                                    className="input-visa"
                                    label="Residential Email"
                                    type="email"
                                    defaultValue="2017-05-24"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={residentialEmail}
                                    onChange={(e) => setResidentialEmail(e.target.value)}
                                />
                                <TextField
                                    className="input-visa"
                                    label="Residential Phone"
                                    type="text"
                                    defaultValue="2017-05-24"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={residentialPhone}
                                    onChange={(e) => setResidentialPhone(e.target.value)}
                                />
                            </>
                            :
                            <TextField
                                helperText=""
                                className="input-visa"
                                label="Name of the hotel"
                                variant="outlined"
                                value={hotelName}
                                onChange={(e) => setHotelName(e.target.value)}
                            />
                        }

                        <button
                            type="button"
                            // onClick={() => setDisplayBlock(5)} 
                            onClick={handleStepFiveContinue}
                            className="step-continue"
                        >
                            Continue
                        </button>
                        <p onClick={() => { setDisplayBlock(3); setSideBarTitle('Passport / Traveler'); }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>{/* Fourth step stops here */}

                    <div className={displayBlock === 5 ? "steps block" : "steps no-block"}>
                        <p className="medical-visa">Medical Insurance covers only emergency cases. All of the companies are approved and certified in Saudi Arabia</p>
                        <div className="medical-wrap">
                            <div onClick={() => {
                                (box !== 0) ? setBox(0) : setBox(null);
                                setMedicalInsurance('arabian_shield');
                            }} className={box === 0 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={arabianShield} alt='arabianShield' />
                                <p className="medical-name">ARABIAN SHIELD COOPERATIVE INSURANCE COMPANY</p>
                            </div>
                            <div onClick={() => {
                                (box !== 1) ? setBox(1) : setBox(null);
                                setMedicalInsurance('saudi_united');
                            }} className={box === 1 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={Wala} alt='Wala' />
                                <p className="medical-name">SAUDI UNITED COOPERATIVE INSURANCE (WALAA)</p>
                            </div>
                            <div onClick={() => {
                                (box !== 2) ? setBox(2) : setBox(null);
                                setMedicalInsurance('tawuniya');
                            }} className={box === 2 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={Tawuniya} alt='Tawuniya' />
                                <p className="medical-name">TAWUNIYA COOPERATIVE INSURANCE COMPANY</p>
                            </div>
                            <div onClick={() => {
                                (box !== 3) ? setBox(3) : setBox(null);
                                setMedicalInsurance('bupa_arabia');
                            }} className={box === 3 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={Bupa} alt='Bupa' />
                                <p className="medical-name">BUPA ARABIA FOR COOPERATIVE INSURANCE</p>
                            </div>
                            <div onClick={() => {
                                (box !== 4) ? setBox(4) : setBox(null);
                                setMedicalInsurance('medgulf');
                            }} className={box === 4 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={Medgulf} alt='Medgulf' />
                                <p className="medical-name">MEDGULF COMPANY</p>
                            </div>
                            <div onClick={() => {
                                (box !== 5) ? setBox(5) : setBox(null);
                                setMedicalInsurance('gulf_union');
                            }} className={box === 5 ? "medical-companies boxShadow" : "medical-companies"}>
                                <img src={gulfUnion} alt='gulfUnion' />
                                <p className="medical-name">GULF UNION COOPERATIVE INSURANCE COMPANY</p>
                            </div>

                        </div>
                        <button 
                        type="button" 
                        // onClick={() => { setDisplayBlock(6); console.log("box: ", box)}} 
                            onClick={handleStepSixContinue}
                        className="step-continue">
                            Continue
                        </button>
                        <p onClick={() => { setDisplayBlock(4); setSideBarTitle('Residence address in Saudi Arabia'); }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>
                    <div className={displayBlock === 6 ? "steps block" : "steps no-block"}>
                        <p className="terms">Terms & Conditions</p>
                        <div className="terms-wrapper">
                            <p>
                                Way Umrah - Terms and Conditions

                                - I acknowledge that all the information I provided is true and reliable. In addition, I pledge to abide by the laws and rules of the Kingdom of Saudi Arabia and respect the customs and Islamic traditions of its people during my stay. I acknowledge my understanding that the specialized authorities in the Kingdom of Saudi Arabia have the right to deny my entry and can send me back to where I came from if I did not comply with the rules and laws; or if the information under which I received my visa are proven to be incorrect.

                                <br></br><br></br><br></br>


                                - I am fully aware that all intoxicating substances, narcotic drugs, indecent materials and publications, as well as publications related to any religious beliefs or political tendencies that contradict with Islam are prohibited in the Kingdom of Saudi Arabia.
                                <br></br><br></br><br></br>

                                - I am fully aware of the procedures and controls regarding the batch-release and clearance of medicines, containing narcotic or psychotropic substances that are possessed for personal use by patients arriving and departing from The Kingdom. Such procedures and controls are provided in the link below: http://www.sfda.gov.sa/ar/drug/drug_reg/pages/drug_reg.aspx. I am also aware that if these regulations and controls are transgressed, I shall be subjected to sanctions and penalties stated in the Law of Combating Narcotics and Psychotropic Substances.

                                <br></br><br></br><br></br>
                                - I have never been deported from The Kingdom of Saudi Arabia or any other GCC country, nor that I have violated their rules.
                                <br></br><br></br><br></br>

                                - I pledge to Abide to the type of visa I was provided and to its terms and duration; and I shall leave the country before the expiry date specified within the visa. I am also fully aware of the penalty I shall endure in case of my violation to the rules of the Kingdom or to my entry visa; and I shall be subject to the penalty and the execution of the punishments stipulated by the laws regulating the process of handling people entering the Kingdom with visas for Hajj or Umrah and other types of visa issued by the Royal Decree No (m / 42), on 18 / 10 / 1404 H and amended by Royal Decree No (m/9) on 1/5/1420 H. As well as the sanctions on violators of residency and work regulations promulgated by the Council of Ministers resolution No (140) on 6/5/1434 H.
                                <br></br><br></br>
                                - I acknowledge the right of the Saudi authorities to repatriate me from the port of entry at my own expense or deport the Saudi territory after entering it. I acknowledge the right of the Saudi authorities to apply the penalties provided by Law if proven that after I received my visa or residence permit, that I have submitted incorrect papers or documents or provided false statements to any Saudi authority locally or abroad in order to obtain a visa for myself or for someone else; for the reason of entry or to obtain a residence permit or any other official visa. Or being a contributor or partner in providing such information or documents that do not match the truth.
                                <br></br><br></br><br></br>

                                - I acknowledge that drug dealing or smuggling drugs into the Kingdom is a crime punishable by death penalty.

                                <br></br><br></br><br></br>
                                - I acknowledge and pledge again that all written information shall be correct and exactly as it is written in my passport that will be used in my travel and entrance to the Kingdom of Saudi Arabia; and I take full responsibility for it. If it is proven otherwise -or my name appears to be included in a list containing the banned people, then my request shall be rejected, or my visa shall be canceled automatically once granted. Moreover, I acknowledge the right to the Saudi authorities to deport me back to the port of entry at my own expense and I shall not have the right to claim compensation.
                                <br></br><br></br><br></br>

                                - I understand that I am not permitted to use the tourist visa for any purpose other than tourism, and that I should not practice any paid or non-paid work related activity, nor perform Hajj during my stay in Saudi Arabia.
                                <br></br><br></br><br></br>

                                - I acknowledge that there are no medical treatment purposes behind the visit.

                                <br></br><br></br><br></br>
                                - To my knowledge, I have not engaged in physical contact with a member of the public affected by the coronavirus (2019-nCoV).

                                <br></br><br></br><br></br>
                                - Visa application fees are nonrefundable.

                                <br></br><br></br><br></br>
                                - I have read and agreed to Terms and Conditions.

                                <br></br><br></br><br></br>
                                - The duration of stay is 90 days upon entry in Saudi within the validity of the eVisa which is one year - Once the 90 days has been consumed, there will be no extension nor renewal of the eVisa until the expiry of the validity of the eVisa (one year).
                            </p>

                        </div>
                        <button className="step-continue" type='submit'>
                            Submit
                        </button>
                        <p onClick={() => { setDisplayBlock(5); setSideBarTitle('Medical Insurance'); }} className="fgoback"> <img src={goBack} alt='back' />  Return a step</p>
                    </div>

                </form>

            </div>
            <SnackbarCustom open={openAlert} alertStatus={alertStatus} handleClose={() => setOpenAlert(false)}>
                {alertMessage}
            </SnackbarCustom>

        </div>

    );
}
