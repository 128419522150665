import React from 'react'
import { Rating, Typography } from '@mui/material';
import './RatingComponent.scss';

const RatingComponent = ({ rating, info }) => {
    return (
        <div className='rating-container'>
            <Rating
                name="rating-with-reviews"
                value={rating}
                readOnly
            />
            {info && (<Typography className='reviews'>{info}</Typography>)}
        </div>
    )
}

export default RatingComponent