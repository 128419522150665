import React from "react";
import { Link } from "react-router-dom";
import homeLandingImage from "../../assets/images/umralight.png";
import Filter from "../../components/Filter/Filter";
import desertImage from "../../assets/images/desert.png";
import oceanImage from "../../assets/images/Rectangle.png";
import bigDesert from "../../assets/images/big-desert.png";
import ShowHotels from "../../components/showHotels/ShowHotels";
import "./Home.scss";
import Offers from "../../components/Offers/Offers";

export default function Home({ show, hotelData, myClass }) {

    return (
        <div className="Home">
            <img
                id="parallax"
                src={homeLandingImage}
                className="landing-home"
                alt=""
            />
            <Filter show={false} />
            {/* <button onClick={handleForm} className="search-button">
                Search
            </button> */}
            <div className="home-about-us-text">
                <h1 className="section-header">About us</h1>
                <p className="about-us-paragraph">
                    Over the years, we have been providing a wide variety of Hajj and
                    Umrah travel packages to guests like you. Since our inception, every
                    year we have enabled thousands of pilgrims to successfully fulfil
                    their promise to Allah. We have built alliances with numerous hotels,
                    resorts and airlines.
                </p>
                <p className="about-us-paragraph">
                    We are proud of the countless positive testimonials we have received
                    from our guests. We welcome you to explore our website to see what
                    package fits your needs. Feel free to contact us for more information,
                    we’d love to hear from you!
                </p>
            </div>
            <div className="home-about-us-image">
                <img src={desertImage} alt="Desert " />
                <img src={oceanImage} alt="Ocean " />
            </div>
            <h1 className="section-header hotels-header">
                Make your travel experience to Saudi Arabia the best one!{" "}
            </h1>
            {/* <ShowHotels /> */}
            <Offers/>
            <div className="home-footer">
                <img src={bigDesert} alt='bigDesert' />
                <h1 className="displace-home">Start Discovering The Umrah Way</h1>
                <Link to={"/hotels"}>
                    {" "}
                    <button>Search</button>
                </Link>
            </div>
        </div>
    );
}
