import axios from 'axios';

export const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY
    }
});

export const axiosAuth = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY
    }
})