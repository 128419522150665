import React from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

export default function SnackbarCustom(props) {

    return (
        <div>
            <Snackbar anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }} open={props.open} autoHideDuration={4000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity={props.alertStatus}>
                    {props.children}
                </Alert>
            </Snackbar>
        </div>
    );
}