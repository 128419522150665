import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import './Filter.scss'
import HotelData from "./HotelData";
import { useDispatch, useSelector } from "react-redux";
import { searchHotels, searchHotelsParams } from "../../store/Actions/searchHotelsAction";
import { InputAdornment } from "@mui/material";

import logoWithoutText from "../../assets/images/logo-without-text.svg";
import locationImage from "../../assets/images/location.svg";
import guestsImage from "../../assets/images/guests.svg";
import qabja from "../../assets/images/qabja.svg";
import hotel from '../../assets/images/hotel.svg';


const locations = ["Medina", "Mecca", "Riyadh"];
const InputIcon = ({ className, img, style }) => (
    <img
        className={className}
        src={img}
        alt={className}
        style={style}
    />
)

function Filter({ show }) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { pathname } = useLocation();

    const { loading, data } = useSelector(state => state?.searchHotels);
    const searchParams = useSelector(state => state.searchHotelParams.data);

    const [locationValue, setLocationValue] = useState(searchParams?.location || '');
    const [bedsValue, setBedsValue] = useState(searchParams?.beds);
    const [distanceValue, setDistanceValue] = useState(searchParams?.distance);
    const [hotelNameValue, setHotelNameValue] = useState(searchParams?.hotelName || '');
    const [page, setPage] = useState(0);
    const trigger = useRef(false);

    useEffect(() => {
        const params = {
            location: locationValue || '',
            beds: bedsValue || '',
            distance: distanceValue || '',
            hotelName: hotelNameValue || '',
            page
        }
        dispatch(searchHotels(params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setPage(0);
    }, [locationValue, bedsValue, distanceValue, hotelNameValue])

    useEffect(() => {
        if (trigger.current) {
            trigger.current = false;
            handleSearchHotels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const handleSearchHotels = () => {
        const params = {
            location: locationValue || '',
            beds: bedsValue || '',
            distance: distanceValue || '',
            hotelName: hotelNameValue || '',
            page
        }
        dispatch(searchHotelsParams(locationValue, bedsValue, distanceValue, hotelNameValue));
        dispatch(searchHotels(params)).then(res => {
            if (pathname !== '/hotels') {
                navigate("/hotels");
            }
        })
    };

    const handleChangePage = async (event, value) => {
        event.preventDefault();
        trigger.current = true;
        setPage(value);
    }

    return (
        <>
            <div className={pathname === '/hotels' ? 'home-searchi' : 'home-search'}>
                <div className="fullwidthi">
                    <img id="parallax" src={logoWithoutText} alt='logoWithoutText' />
                </div>
                <Autocomplete
                    className={`top-hidden-arrow ${pathname === '/hotels' ? 'input-search' : ''}`}
                    FullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={locations}
                    show={false}
                    sx={{ width: 300 }}
                    value={locationValue}
                    onChange={(event, newValue) => {
                        setLocationValue(newValue);
                    }}
                    getOptionLabel={option => option}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Location"
                        />
                    )}
                />
                <InputIcon className="testii" img={locationImage} style={{ marginRight: "10px" }} />
                <TextField
                    className={`top-hidden-arrow   ${pathname === '/hotels' ? 'input-search' : ''}`}
                    id="outlined-basic"
                    type="number"
                    value={bedsValue}
                    onChange={(e) => setBedsValue(e.target.value)}
                    label="Beds"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'><InputIcon img={guestsImage} style={{ width: '24px', height: '24px' }} /></InputAdornment>
                        ),
                    }}
                />

                <TextField
                    className={`top-hidden-arrow   ${pathname === '/hotels' ? 'input-search' : ''}`}
                    id="outlined-basic"
                    type="number"
                    value={distanceValue}
                    onChange={(e) => setDistanceValue(e.target.value)}
                    label="Distance"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'><InputIcon img={qabja} style={{ width: '24px', height: '24px' }} /></InputAdornment>
                        ),
                    }}
                />

                <TextField
                    className={`top-hidden-arrow   ${pathname === '/hotels' ? 'input-search' : ''}`}
                    id="outlined-basic"
                    type="text"
                    value={hotelNameValue}
                    onChange={(e) => setHotelNameValue(e.target.value)}
                    label="Hotel Name"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'><InputIcon img={hotel} style={{ width: '24px', height: '24px' }} /></InputAdornment>
                        ),
                    }}
                />
                {pathname === '/hotels' && (<button onClick={() => handleSearchHotels()} className={pathname === '/hotels' ? 'search-buttoni' : 'search-button'}>Search</button>)}
            </div>
            {pathname !== '/hotels' && (<button onClick={() => handleSearchHotels()} className={pathname === '/hotels' ? 'search-buttoni' : 'search-button'} style={{ marginTop: '-25px' }}>Search</button>)}
            {show ?
                <HotelData loading={loading} data={data} handleChangePage={handleChangePage} /> :
                null
            }
        </>
    );
}

export default Filter;
