import React from "react";
import "./Offers.scss";
import firstOffer from "../../assets/images/Oferta1.jpg";
import secondOffer from "../../assets/images/Oferta2.jpg";
import thirdOffer from "../../assets/images/Oferta3.jpg";
import fourthOffer from "../../assets/images/Oferta4.jpg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select"; // Fix import statement
import { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const Offers = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const [offerTitle, setOfferTitle] = useState("");
  // const [paragraph, setParagraph] = useState("");
  // const firstOfferText = `Aranzhmani përfshinë: 

  // Qëndrimin në SwissÖtel Al Maqam në Mekke (4 netë me mëngjes) 
  // Qëndrimin në hotel me 5* në Medine (2 netë me mëngjes)
  // Udhëtimin nga Tirana në Jeddah dhe kthimi nga Medina në Tiranë
  // Vizën e Umres
  // Biletat e udhëtimit me Turkish Airlines
  // Transport të brendshëm
  // Vizitat në Meke dhe Medine
  // Ihram
  // Ujë Zemzem
  // Udhëheqës Umre`;
  // const secondOfferText = `Aranzhmani përfshinë: 

  // Qëndrimin në SwissÖtel Al Maqam në Mekke (5 netë me mëngjes)
  // Qëndrimin në hotel me 5* në Medine (4 netë me mëngjes)
  // Udhëtimin nga Tirana në Jeddah dhe kthimi nga Medina në Tiranë
  // Vizën e Umres
  // Biletat e udhëtimit me Turkish Airlines
  // Transport të brendshëm
  // Vizitat në Meke dhe Medine
  // Ihram
  // Ujë Zemzem
  // Udhëheqës Umre`;
  // const thirdOfferText = `Aranzhmani përfshinë: 

  // Qëndrimin në Le Meredien Towers në Mekke (4 netë me mëngjes)
  // Qëndrimin në hotel me 5* në Medine (2 netë me mëngjes)
  // Udhëtimin nga Tirana në Jeddah dhe kthimi nga Medina në Tiranë
  // Vizën e Umres
  // Biletat e udhëtimit me Turkish Airlines
  // Transport të brendshëm
  // Vizitat në Meke dhe Medine
  // Ihram
  // Ujë Zemzem
  // Udhëheqës Umre`;
  // const fourthOfferText = `
  //  Aranzhmani përfshinë: 
   
  //  Qëndrimin në Le Meredien Towers në Mekke (5 netë me mëngjes)
  //  Qëndrimin në hotel me 5* në Medine (4 netë me mëngjes)
  //  Udhëtimin nga Tirana në Jeddah dhe kthimi nga Medina në Tiranë
  //  Vizën e Umres
  //  Biletat e udhëtimit me Turkish Airlines
  //  Transport të brendshëm
  //  Vizitat në Meke dhe Medine
  //  Ihram
  //  Ujë Zemzem
  //  Udhëheqës Umre`;
  // function openForm(title, included) {
  //   setIsOpen(true);
  //   setOfferTitle(title);
  //   setParagraph(included);
  // }

  // function closeForm() {
  //   setIsOpen(false);
  // }

  return (
    <div className="offersWrapper">
      {/* {isOpen && (
        <div className="popUpForm" id="popUpForm">
          <h2 className="formTitle">{offerTitle}</h2>
          <p className="parag">{paragraph}</p>
          <svg
            onClick={closeForm}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000"
            strokeWidth={2} // Fix strokeWidth attribute
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <form className="fields">
            <TextField
              className={"inputs"}
              id="outlined-basic"
              type="text"
              label="Emri"
              variant="outlined"
            />
            <TextField
              className={"inputs"}
              id="outlined-basic"
              type="text"
              label="Mbiemri"
              variant="outlined"
            />
            <TextField
              className={"inputs"}
              id="outlined-basic"
              type="text"
              label="Email"
              variant="outlined"
            />
            <div className="birthday">
              <FormHelperText>Date of Birth</FormHelperText>
              <TextField
                className={"inputs"}
                id="outlined-basic"
                type="date"
                variant="outlined"
              />
            </div>

            <div className="gender">
              <input name="gender" id="male" type="radio" />{" "}
              <label htmlFor="male">Male</label>
              <input name="gender" id="female" type="radio" />
              <label htmlFor="female">Female</label>
            </div>
            <button className="submitForm">Submit</button>
          </form>
        </div>
      )} */}
      <div className="offersDiv">
        <img
          // onClick={() => openForm("Vip Umre, SwissÖtel", firstOfferText)}
          src={firstOffer}
          alt=""
        />
        <img
          // onClick={() => openForm("Vip Umre, Swissötel", secondOfferText)}
          src={secondOffer}
          alt=""
        />
        <img
          // onClick={() => openForm("Umre, Le meridlen Towers", thirdOfferText)}
          src={thirdOffer}
          alt=""
        />
        <img
          // onClick={() => openForm("Umre, Le Meridien Towers", fourthOfferText)}
          src={fourthOffer}
          alt=""
        />
      </div>
      <h1>Our most recent offers!</h1>
    </div>
  );
};

export default Offers;
