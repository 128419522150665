import React, { useState, useEffect, useRef } from 'react'
import './HotelView.scss';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { searchFreeRoomsByHotelId, searchHotelById, createReservation } from '../../store/Actions/searchHotelsAction';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, IconButton } from '@mui/material';
import { Divider, SvgIcon, Typography, List, ListItem, ListItemText, Card, ListItemIcon, CardMedia } from '@mui/material';
import { ReactComponent as locationImage } from "../../assets/images/location.svg"
import { ReactComponent as guestsImage } from "../../assets/images/guests.svg";
import { ReactComponent as calendarImage } from "../../assets/images/calendar.svg";
import ViewImage from '../../components/viewImageModal/ViewImage';
import RatingComponent from '../../components/rating/RatingComponent';
import Loading from '../../components/loader/Loading';
import moment from 'moment'
import { DateRange } from 'react-date-range';
import ViewModal from '../../components/Modal/ViewModal';
import CloseIcon from '@mui/icons-material/Close';
// import SnackbarCustom from '../../components/snackbar/SnackbarCustom';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import logoWithText from '../../assets/images/color-logo.svg';
import qabja from "../../assets/images/qabja.svg";
import ShowHotels from '../../components/showHotels/ShowHotels';


const style = {
    width: '100%',
    bgcolor: 'background.paper',
};

const HotelView = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { loading, payload } = useSelector(state => state?.hotelById);
    const [hotelData, setHotelData] = useState();
    const [hotelRooms, setHotelRooms] = useState([]);
    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [checkInOut, setCheckInOut] = useState([{ startDate: new Date(), endDate: null, key: 'selection' }]);
    const [filtered, setFiltered] = useState(false);
    const [roomTypes, setRoomTypes] = useState('');
    const [totalNights, setTotalNights] = useState(0);
    const [beds, setBeds] = useState(0);
    const [price, setPrice] = useState(0);
    const roomReservationRef = useRef();

    // const [openAlert, setOpenAlert] = useState(false);
    // const [alertStatus, setAlertStatus] = useState('');
    // const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(searchHotelById(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (!loading) {
            setHotelData(payload?.data?.data?.attributes)
            setHotelRooms(payload?.data?.data?.attributes?.rooms)
            setImages(payload?.data?.data?.attributes?.filename)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload])

    useEffect(() => {
        if (checkInOut[0]?.endDate) {
            setTotalNights((checkInOut[0].endDate - checkInOut[0].startDate) / (1000 * 3600 * 24));
        }
        setFiltered(false);
        setSelected([]);
    }, [checkInOut])



    useEffect(() => {
        let allRoomTypes = '';
        let price = 0;
        let beds = 0;
        hotelRooms?.map(hotel => {
            if (selected?.includes(hotel?.id)) {
                allRoomTypes += `${hotel?.title}, `
                price += parseFloat(hotel?.price);
                beds += parseInt(hotel?.beds)
            }
        })
        setRoomTypes(allRoomTypes);
        setBeds(beds)
        setPrice(price);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    const handleFilterRooms = () => {
        // console.log('checkInOut', checkInOut)
        const params = {
            id,
            fromDate: moment(checkInOut[0].startDate).format('DD-MM-YYYY'),
            toDate: moment(checkInOut[0].endDate).format('DD-MM-YYYY')
        }
        dispatch(searchFreeRoomsByHotelId(params)
        ).then(res => {
            setHotelRooms(res?.payload?.data?.data);
            setFiltered(true)
            roomReservationRef.current.scrollIntoView()
        })
    }

    const handleCreateReservation = () => {
        const params = {
            room_id: selected,
            hotel_id: parseInt(id),
            checkin: moment(checkInOut[0].startDate).format('DD-MM-YYYY'),
            checkout: moment(checkInOut[0].endDate).format('DD-MM-YYYY')
        }
        dispatch(createReservation(params));
    }

    function handleCheck(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    }

    function getCommaSeparatedTwoDecimalsNumber(number) {
        const fixedNumber = Number.parseFloat(number).toFixed(2);
        return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }


    return (
        <div className='hotel-view'>
            <div className='main-gallery'>
                <div className='side-bar'>
                    {/* <DateRange checkIn={checkIn} setCheckIn={setCheckIn} checkOut={checkOut} setCheckOut={setCheckOut} /> */}
                    <div className='filter-card'>
                        <div className="logo">
                            <img id="parallax" src={logoWithText} alt='logoWithoutText' />
                        </div>

                        <List sx={style} component="nav" aria-label="mailbox folders">
                            <Divider />
                            <ListItem button divider alignItems="center">
                                <ListItemIcon>
                                    <SvgIcon component={locationImage} inheritViewBox />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="caption">Location</Typography>} secondary={<Typography style={{ fontWeight: 'bold' }}>{hotelData?.location || ''}</Typography>} />
                            </ListItem>
                            <ListItem button divider>
                                <ListItemIcon>
                                    <SvgIcon component={guestsImage} inheritViewBox />
                                </ListItemIcon>
                                <ListItemText primary={<Typography variant="caption">Beds</Typography>} secondary={<Typography style={{ fontWeight: 'bold' }}>{`${hotelData?.rooms_number} Rooms` || ''}</Typography>} />
                            </ListItem>
                            <ListItem button divider>
                                <ListItemIcon>
                                    <SvgIcon component={calendarImage} inheritViewBox />
                                </ListItemIcon>
                                <ListItemText
                                    onClick={() => setOpenDateRange(true)}
                                    primary={<Typography variant="caption">Check In & Check out Dates</Typography>}
                                    secondary={<Typography style={{ fontWeight: 'bold' }}>
                                        {checkInOut[0]?.startDate ? moment(checkInOut[0]?.startDate).format('MMM DD, YYYY') : ''} -
                                        {checkInOut[0]?.endDate ? moment(checkInOut[0]?.endDate).format('MMM DD, YYYY') : ''}</Typography>
                                    }
                                />
                            </ListItem>

                        </List>

                        <button className={'search-buttoni'} onClick={() => handleFilterRooms()}>Filter</button>
                    </div>
                </div>
                <div className='hotel-gallery'>
                    {loading ?
                        <Loading />
                        : (
                            <>
                                <div className='hotel-rating-info my-20'>
                                    <div>
                                        <img
                                            src={qabja}
                                            alt='qabja'
                                            className='qabja_icon mr-10'
                                        />
                                        {parseInt(hotelData?.distance_from_kaaba)} km away from Kaaba
                                    </div>
                                    <RatingComponent rating={parseFloat(hotelData?.rating / 2)} info={`${hotelData?.reviews?.length} reviews`} />
                                </div>
                                <div className='gallery'>
                                    <div className='cover-img'>
                                        <Card sx={{ maxWidth: '100%' }}>
                                            <CardMedia
                                                component="img"
                                                image={hotelData?.thumbnail_img}
                                                alt="thumbnail"
                                                sx={{ height: '620px' }}
                                            />
                                        </Card>
                                    </div>
                                    <div className='more-img'>
                                        {images?.slice(0, 3)?.map((img, indx) => (
                                            <Card sx={{ width: '100%', height: '200px', position: 'relative' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={img?.filename}
                                                    key={img?.id}
                                                    alt="thumbnail"
                                                    sx={{ width: '100%', height: '100%' }}
                                                    onClick={() => setOpenImage(true)}
                                                />
                                                {(indx === 2 && images?.length > 3) && (<div className='more_img' onClick={() => setOpenImage(true)}>{`+${images?.length - 3} more`}</div>)}
                                            </Card>
                                        ))}

                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </div>
            {!loading && (<>
                <div className='hotel-intro'>
                    <Typography variant='h5'>{hotelData?.name}</Typography>
                    <Typography variant="body1" paragraph style={{ lineHeight: "48px" }}>{hotelData?.description || ''}</Typography>
                </div>
                <div className='room-reservation' ref={roomReservationRef}>
                    <Typography variant='h5' className='my-20'>Room Reservation</Typography>
                    <TableContainer component={Paper} style={{ display: 'flex' }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableCell className='white' align="right">Select</TableCell>
                                    <TableCell className='white' align="right">Room Type</TableCell>
                                    <TableCell className='white' align="right">Beds</TableCell>
                                    <TableCell className='white' align="right">Includes</TableCell>
                                    <TableCell className='white' align="right">Price</TableCell>
                                    <TableCell className='white' align="right">Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hotelRooms?.map((row, index) => {
                                    const isSelected = selected.indexOf(row.id) !== -1;
                                    return (
                                        <TableRow key={row.id}
                                            role="checkbox"
                                        >
                                            <TableCell component="th" scope="row">
                                                {filtered && (<Checkbox
                                                    checked={isSelected}
                                                    onClick={event => event.stopPropagation()}
                                                    onChange={event => handleCheck(event, row.id)}
                                                />)}
                                            </TableCell>
                                            <TableCell align="right">{row.title}</TableCell>
                                            <TableCell align="right">{row.beds}</TableCell>
                                            <TableCell align="right">{row.description}</TableCell>
                                            <TableCell align="right">{row.price}</TableCell>
                                            {index === 0 && (
                                                <TableCell rowSpan={4}>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none' }}>Room type: </TableCell>
                                                        <TableCell style={{ border: 'none' }}>{roomTypes}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none' }}>Beds: </TableCell>
                                                        <TableCell style={{ border: 'none' }}>{beds}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none' }}>Nights: </TableCell>
                                                        <TableCell style={{ border: 'none' }}>{totalNights}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none' }}>Total Price: </TableCell>
                                                        <TableCell style={{ border: 'none' }}>{getCommaSeparatedTwoDecimalsNumber(price * totalNights)}  €</TableCell>
                                                    </TableRow>
                                                    <TableRow align='center'>
                                                        <TableCell className='border-none' colSpan={2}>
                                                            <button className='book_now_in_table' onClick={handleCreateReservation}>Book Now</button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='related-hotels'>
                    <Typography variant='h5' className='my-20'>Related</Typography>
                    <ShowHotels />
                </div>
                {openImage && (
                    <ViewImage images={images} imageIndex={imageIndex} setImageIndex={setImageIndex} closeModal={() => setOpenImage(false)} />
                )}
                <ViewModal open={openDateRange} close={() => setOpenDateRange(false)}>
                    <>
                        <div className='close_date_range'>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenDateRange(false)}
                                style={{ backgroundColor: 'white', color: '#75c19f' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <DateRange
                            editableDateInputs={true}
                            minDate={new Date()}
                            onChange={item => setCheckInOut([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={checkInOut}
                            rangeColors={['#75c19f']}
                            color={'#75c19f'}
                            dateDisplayFormat='dd-MM-yyyy'
                        />
                    </>
                </ViewModal>
            </>)}
            {/* <SnackbarCustom open={openAlert} alertStatus={alertStatus} handleClose={() => setOpenAlert(false)}>
                {alertMessage}
            </SnackbarCustom> */}
        </div>

    )
}

export default HotelView