import { axiosAuth, axiosConfig } from '../../services/axios';
import {
    SEARCH_HOTEL_REQUEST,
    SEARCH_HOTEL_SUCCESS,
    SEARCH_HOTEL_FAIL,
    SET_SEARCH_HOTEL_PARAMS,
    SEARCH_HOTEL_BY_ID_REQUEST,
    SEARCH_HOTEL_BY_ID_SUCCESS,
    SEARCH_HOTEL_BY_ID_FAIL,
    SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST,
    SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS,
    SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL,
    CREATE_RESERVATION_REQUEST,
    CREATE_RESERVATION_SUCCESS,
    CREATE_RESERVATION_FAIL,
    GET_ALL_HOTELS_REQUEST,
    GET_ALL_HOTELS_SUCCESS,
    GET_ALL_HOTELS_FAIL
} from '../constants/filterHotels';

export const searchHotels = (params) => async (dispatch) => {
    const { location, beds, distance, hotelName, page } = params;
    try {
        dispatch({ type: SEARCH_HOTEL_REQUEST });
        const data = await axiosConfig.get(`/v1/hotels?page=${page}&per_page=10&name=${hotelName}&location=${location}&distance_from_kaaba=${distance}&beds=${beds}`);
        dispatch({
            type: SEARCH_HOTEL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SEARCH_HOTEL_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}

export const searchHotelsParams = (location, beds, distance, hotelName) => async (dispatch) => {
    dispatch({
        type: SET_SEARCH_HOTEL_PARAMS,
        payload: {
            location: location,
            beds: beds,
            distance: distance,
            hotelName: hotelName
        }
    })
}

export const searchHotelById = (id) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_HOTEL_BY_ID_REQUEST });
        const data = await axiosConfig.get(`/v1/hotels/${id}`);
        dispatch({
            type: SEARCH_HOTEL_BY_ID_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SEARCH_HOTEL_BY_ID_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}

export const searchFreeRoomsByHotelId = (params) => async (dispatch) => {
    const { id, fromDate, toDate } = params;
    try {
        dispatch({ type: SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST });
        const data = await axiosAuth.post(`/v1/not_reservation_room`, {
            checkin: fromDate,
            checkout: toDate,
            hotel_id: id
        });
        dispatch({
            type: SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS,
            payload: data
        })
        return { payload: data }
    } catch (error) {
        dispatch({
            type: SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}


export const createReservation = (params) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_RESERVATION_REQUEST });
        const data = await axiosAuth.post(`/v1/reservations`, params);
        dispatch({
            type: CREATE_RESERVATION_SUCCESS,
            payload: 'Your created booking successfully'
        })
        return { payload: data }
    } catch (error) {
        dispatch({
            type: CREATE_RESERVATION_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}

export const getAllHotels = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_HOTELS_REQUEST });
        const data = await axiosAuth.get(`/v1/hotel_list`);
        dispatch({
            type: GET_ALL_HOTELS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_HOTELS_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}