import "./Main.scss";
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Header from './components/Header/Header';
import Visa from './pages/Visa-Application/Visa-Application'
import SingleHotel from './pages/Single-Hotel/Single-Hotel'
import SignUp from './pages/Sign-Up/Sign-Up'
import Cart from './pages/Cart/Cart'
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import FilterPage from './pages/Filter-Page/FilterPage';
import Login from './pages/Login/LoginPage';
import HomeSearch from './pages/Home';
import HotelView from "./pages/Hotel-View/HotelView";
import MyReservation from "./pages/Reservation/MyReservation";
import MyVisaApplication from "./pages/MyVisaApplication/MyVisaApplication";
import AuthProvider from "./auth/AuthProvider";
import ProtectedRoute from './auth/ProtectedRoute';

function App() {
    return (
        <>
            <AuthProvider>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="/home-search" element={<HomeSearch />}></Route>
                    <Route path="single-hotel" element={<SingleHotel />} />
                    <Route path="sign-up" element={<SignUp />} />
                    <Route path="login" element={<Login />} />

                    <Route path="admin-dashboard" element={<AdminDashboard />} />
                    <Route path="cart" element={<Cart />} />
                    <Route path="hotels" element={<FilterPage />} />
                    <Route path='hotel/:id' element={<HotelView />} />
                    <Route path="/visa-application" element={
                        <ProtectedRoute>
                            <Visa />
                        </ProtectedRoute>
                    } />
                    <Route path="/my-reservation" element={
                        <ProtectedRoute>
                            <MyReservation />
                        </ProtectedRoute>
                    } />
                    <Route path="/my-visa-application" element={
                        <ProtectedRoute>
                            <MyVisaApplication />
                        </ProtectedRoute>
                    } />
                </Routes>
            </AuthProvider>
        </>
    );
}

export default App;