// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// const Protected = ({ children }) => {

//     const isLoggedIn = useSelector((state) => state?.userData?.isLogged);
//     const userData = useSelector((state) => state?.userData?.userData);
//     console.log('test' + userData);

//     return isLoggedIn ? children : <Navigate to="/login" />;


// }
// export default Protected;

import {
    Navigate,
    useLocation
} from 'react-router-dom';
import { useAuth } from '../hooks/useAuthHook';

const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();

    if (!token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    return children;
};

export default ProtectedRoute;