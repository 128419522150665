import { USER_REQUEST, USER_SUCCESS, USER_FAIL } from "../constants/user";
import { axiosAuth } from "../../services/axios";

export function isUserLogged(data) {
    return {
        type: "IS_LOGGED",
        payload: data,
    };
}

export function loggedUserData(data1) {
    return {
        type: "USER_DATA",
        payload: data1,
    };
}


export const getUserData = () => async (dispatch) => {
    try {
        dispatch({ type: USER_REQUEST });
        const data = await axiosAuth.get(`/user`);
        dispatch({
            type: USER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_FAIL,
            payload: error?.response?.data?.message ? error?.response?.data?.message : "There was an error!"
        })
    }
}