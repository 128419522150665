import React from "react";
import { useState } from "react";
import axios from "axios";
import "./AddHotel.scss";
import TextField from "@mui/material/TextField";
import Service from "../../services/general";
import upload from "../../assets/images/upload.svg"
function AddHotel() {
  const [nameValue, setNameValue] = useState("");
  const [descValue, setDescValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [bedsValue, setBedsValue] = useState("");

  const handleNameChange = (event) => {
    const value = event.target?.value;
    setNameValue(value);
  };

  const handleDescChange = (event) => {
    const value = event.target?.value;
    setDescValue(value);
  };

  const handlePriceChange = (event) => {
    const value = event.target?.value;
    setPriceValue(value);
  };

  const handleBeedsChange = (event) => {
    const value = event.target?.value;
    setBedsValue(value);
  };

  const handleForm = (event) => {
    event.preventDefault();
    createHotel();
  };


  //   const createRoom = () => {
  //     const data = {
  //       hotel_id: "5",
  //       title: nameValue,
  //       description: descValue,
  //       price: priceValue,
  //       beds: bedsValue,
  //     };
  const createHotel = () => {
    const data = {
      name: nameValue,
      description: descValue,
      price: 4,
      location: "test",
      distance_from_kaaba: "test",
      filename: ["img1", "img2"],
      rooms: [
        {
          title: nameValue,
          description: descValue,
          price: priceValue,
          beds: bedsValue,
        },
        {
          title: nameValue,
          description: descValue,
          price: priceValue,
          beds: bedsValue,
        },
      ],
      cover_img: "test",
      thumbnail_img: "test",
    };
    axios
      .post(
        "http://apiwayumrah.origin3agency.com/api/v1/hotel/multiple/rooms",
        data,
        {
          headers,
        }
      )
      .then((res) => console.log(res, "res"))
      .catch((err) => console.log(err));
  };
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": "#. babo-ska-me-hi-knej-007",
  };

  //     axios
  //       .post("http://apiwayumrah.origin3agency.com/api/v1/rooms", data, {
  //         headers,
  //       })
  //       .then((res) => console.log(res, "res"))
  //       .catch((err) => console.log(err));
  //   };


 
function secondStep(){
  document.getElementById("step-info").style.display  = "none";
  document.getElementById("rooms-step").style.display = "block";
}
function goFirstStep(){
  document.getElementById("step-info").style.display = "block";
  document.getElementById("rooms-step").style.display = "none";
}
  return (
    <div>
      <div id="addRooms" className="addRooms">
      <div class="add-steps">
            <div  id="first-step">
              <p className="number-steps">1</p>
              <div>
                <p className="steps-header">Hotel Information</p>
                <p className="steps-parag">Fill Hotel Information</p>
              </div>
            </div>
            <div id="second-step">
              <p className="number-steps">2</p>
              <div>
                <p className="steps-header">Hotel Rooms</p>
                <p className="steps-parag">Enter Rooms Information</p>
              </div>
            </div>
            <div id="third-step">
              <p className="number-steps">3</p>
              <div>
                <p className="steps-header">Confirm Hotel</p>
                <p className="steps-parag">Verify Hotel Details</p>
              </div>
            </div>

          </div>
        <div id="step-info" className="step-info">
        <button onClick={secondStep} className="next-button"  type="submit">
        Next
      </button>
    
        <div className="add-hotel-input-wrapper">
        

          <TextField
            id="outlined-basic"
            onChange={handleNameChange}
            className="input-rooms"
            label="Hotel Name"
            
            variant="outlined"
          />
        </div>
        <div className="add-hotel-input-wrapper klas">
          <TextField
           multiline
            id="outlined-basic"
            onChange={handleDescChange}
          
            className="input-rooms heightof-inpt"
            label="Hotel Description"
            variant="outlined"
          />
        </div>
        <div className="desc-input">
          <p>This image will be used as a cover</p>
          <p>These are secondary images</p>
        </div>
      <div className="add-hotel-input-wrapper file-upload-wrapper">
      <label className="big-input" for="file-input">
    <img src={upload}/>
    <p>Upload File</p>
  </label>
  <input id="file-input" type="file" />
  <input id="file-input2" type="file" />

    <div className="vertical-line"></div>
  <label className="small-input" for="file-input">
    <img src={upload}/>
    <p>Upload File</p>
  </label>      </div>
      </div>
      <div id="rooms-step">
      <button onClick={handleForm}  className="next-button"  type="submit">
        Next
      </button>
      <button onClick={goFirstStep} className="back-button"  type="submit">
        Back
      </button>
      <div className="add-hotel-input-wrapper">
        

        <TextField
          id="outlined-basic"
          className="input-rooms"
          label="Room Type"
          
          variant="outlined"
        />
      </div>
      <div className="add-hotel-input-wrapper">
        

        <TextField
          id="outlined-basic"
          className="input-rooms"
          label="Room Description"
          
          variant="outlined"
        />
      </div>
      <div className="input-step-half">
      <div className="add-hotel-input-wrapper">
        

        <TextField
          id="outlined-basic"
          className="input-rooms-half"
          label="Number of Beds"
          
          variant="outlined"
        />
      </div>
      <div className="add-hotel-input-wrapper">
        

        <TextField
          id="outlined-basic"
          className="input-rooms-half"
          label="Price"
          
          variant="outlined"
        />
      </div>
      </div>
      <p className="add-room-txt">Add another Room</p>

      
      </div>
     </div>
    </div>
  );
}
export default AddHotel;
