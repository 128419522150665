import React from "react";
import Filter from "../components/Filter/Filter";

function Home() {
  return (
    <div className="container pt-3">
      <h4> Lorem ipsum dolor </h4>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officiis enim
        cumque dolorum libero ratione architecto, hic eius commodi suscipit,
        assumenda culpa, provident consectetur porro! Dignissimos ipsa ea hic
        provident labore.
      </p>
      {}
      <Filter
        show={false}
        myClass='filter-home'
      />
    </div>
  );
}

export default Home;
