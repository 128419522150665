import React from "react";
import Filter from "../../components/Filter/Filter";
import './FilterPage.scss'

function FilterPage() {
    return (
        <div className="search-the-hotels">
            <div className="filtering-system">

            </div>
            <div className={'p-4 container m-auto filter-filter'}>
                <Filter
                    show={true}
                    myClass='filter-filter'
                />
            </div>

        </div>
    );
}

export default FilterPage;
