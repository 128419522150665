import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyReservation } from '../../store/Actions/reservationAction';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from '@mui/material';
import './MyReservation.scss';
import moment from 'moment';

const MyReservation = () => {
    const dispatch = useDispatch();
    const { loading, data } = useSelector(state => state?.myReservation)

    useEffect(() => {
        dispatch(getMyReservation())
    }, [])

    if (loading) {
        return (
            <div className='loading'>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color='success' />
                </Box>
            </div>
        )
    }

    return (
        <div id='my-reservation'>
            {data && data?.data?.data?.length > 0 ? (
                <>
                    <Typography variant='h5' className='my-20'>My Reservation</Typography>
                    <TableContainer component={Paper} style={{ display: 'flex', marginBottom: '30px' }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableCell className='white' align="right">ID</TableCell>
                                    <TableCell className='white' align="right">User</TableCell>
                                    <TableCell className='white' align="right">Check In</TableCell>
                                    <TableCell className='white' align="right">Check Out</TableCell>
                                    <TableCell className='white' align="right">Location</TableCell>
                                    <TableCell className='white' align="right">Distance</TableCell>
                                    <TableCell className='white' align="right">Status</TableCell>
                                    <TableCell className='white' align="right">Price</TableCell>
                                    <TableCell className='white' align="right">Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.data?.map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell align="right">{row?.id}</TableCell>
                                            <TableCell align="right">{row?.attributes?.user?.name}</TableCell>
                                            <TableCell align="right">{moment(row?.attributes?.checkin).format('L')}</TableCell>
                                            <TableCell align="right">{moment(row?.attributes?.checkout).format('L')}</TableCell>
                                            <TableCell align="right">{row?.attributes?.hotel?.location}</TableCell>
                                            <TableCell align="right">{row?.attributes?.hotel?.distance_from_kaaba} km</TableCell>
                                            <TableCell align="right">{row?.attributes?.status}</TableCell>
                                            <TableCell align="right">{row?.attributes?.room?.price}</TableCell>
                                            <TableCell align="right">{row?.attributes?.total_price}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ):
                <>
                    <Typography variant='h5' className='my-20'>My Reservation</Typography>
                    <p>Empty!</p>
                </>
        }
        </div>
    )
}

export default MyReservation