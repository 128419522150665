import { USER_REQUEST, USER_SUCCESS, USER_FAIL } from "../constants/user";

const initialValue = {
    isLogged: false,
    userData: [],
};

export const userReducer = (state = initialValue, action) => {
    switch (action.type) {
        case "IS_LOGGED":
            return {
                ...state,
                isLogged: action.payload,
            };
        case "USER_DATA":
            return {
                ...state,
                userData: action.payload,
            };
        default:
            return state;
    }
};

export const userDataReducer = (state = null, action) => {
    switch (action.type) {
        case USER_REQUEST:
            return { loading: true, data: null }
        case USER_SUCCESS:
            return { loading: false, data: action.payload }
        case USER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}