import axios from "axios";
import { useState, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosAuth } from "../services/axios";
import { getUserData } from "../store/Actions/user.actions";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        axiosAuth.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
        axiosAuth.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    localStorage.removeItem("token");
                    setToken(null);
                    navigate('/login');
                }

                return Promise.reject(error);
            }
        );
        if (token)
            dispatch(getUserData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token])

    const handleLogin = async (email, password) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, { email, password }).then(res => {
            setToken(res?.data?.data?.token);
            localStorage.setItem('token', res?.data?.data?.token);
            const origin = location.state?.from?.pathname || '/';
            navigate(origin);
        }).catch(error => {
            localStorage.setItem('failedLogin', true);
            console.log('error', error.message)
            return Promise.reject(error)
        })
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('token');
        navigate('/');
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;