import React from 'react'
import Pagination from '@mui/material/Pagination';
import qabja from "../../assets/images/qabja.svg"
import cardloc from "../../assets/images/cardloc.svg"
import { Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import './HotelData.scss';
import RatingComponent from '../rating/RatingComponent';

const ImageComponent = ({ img, alt, className }) => (<img src={img} alt={alt || ''} className={className} />)

const HotelData = ({ loading, data, handleChangePage }) => {
    if (loading) {
        return (
            <div className="hotelet" style={{ height: '75vh', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color='success' />
                </Box>
            </div>
        )
    }

    return (
        <div className="hotelet">
            {data?.data?.data &&
                data?.data?.data?.map((dataHotel) => (
                    <Link
                        key={dataHotel.id}
                        className="hotels-wrapper-done"
                        to={`/hotel/${dataHotel.id}`}
                    >
                        <div className="card">
                            {dataHotel.attributes.filename[0] ? (
                                <ImageComponent img={dataHotel.attributes.filename[0].filename} alt={dataHotel?.attributes?.name} className="card-img-top cardImg" />
                            ) : (
                                <ImageComponent img={'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt={'No-Image'} className="card-img-top cardImg" />
                            )}

                            <div className="card-body text-left">
                                <RatingComponent rating={parseFloat(dataHotel?.attributes?.rating / 2)} info={`${dataHotel?.attributes?.reviews?.length} reviews`} />
                                <p className="hotel-name">{dataHotel.attributes.name}</p>
                                <div className="hotel-info-wrap">
                                    <ImageComponent img={cardloc} alt={'cardloc'} />
                                    <p className="hotel-info">{dataHotel?.attributes?.location}</p>
                                </div>
                                <div className="hotel-info-wrap">
                                    <ImageComponent img={qabja} alt={'qabja'} />
                                    <p className="hotel-info">
                                        {parseInt(dataHotel.attributes.distance_from_kaaba)} km away from Kaaba
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Link>

                ))}
            {data?.data?.data?.length > 0 ?
                <Pagination
                    count={data?.data?.meta?.last_page || 1}
                    page={data?.data?.meta?.current_page || 1}
                    onChange={handleChangePage}
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '10px' }}
                />
                : null
            }
        </div>
    )
}

export default HotelData