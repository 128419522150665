import {
    GET_RESERVATION_REQUEST,
    GET_RESERVATION_SUCCESS,
    GET_RESERVATION_FAIL,
    GET_VISA_APPLICATION_REQUEST,
    GET_VISA_APPLICATION_SUCCESS,
    GET_VISA_APPLICATION_FAIL
} from '../constants/reservation';

export const getMyReservation = (state = [], action) => {
    switch (action.type) {
        case GET_RESERVATION_REQUEST:
            return { loading: true, data: [] }
        case GET_RESERVATION_SUCCESS:
            return { loading: false, data: action.payload }
        case GET_RESERVATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getMyVisaApplication = (state = [], action) => {
    switch (action.type) {
        case GET_VISA_APPLICATION_REQUEST:
            return { loading: true, data: [] }
        case GET_VISA_APPLICATION_SUCCESS:
            return { loading: false, data: action.payload }
        case GET_VISA_APPLICATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}