import React from 'react'
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";


const ViewImage = ({ images, imageIndex, setImageIndex, closeModal }) => {
    return (
        <Lightbox
            mainSrc={images[imageIndex]?.filename}
            nextSrc={images[(imageIndex + 1) % images.length]}
            prevSrc={images[(imageIndex + images.length - 1) % images.length]}
            onCloseRequest={closeModal}
            onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
        />
    )
}

export default ViewImage