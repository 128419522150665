import React from 'react'
import { Link } from 'react-router-dom'
import logoWithoutText from '../../assets/images/logo-without-text.svg'
import locationImage from '../../assets/images/location.svg'
import guestsImage from '../../assets/images/guests.svg'
import calendarImage from '../../assets/images/calendar.svg'
import hotelImage from '../../assets/images/hotel.svg'
import AlMashreq from '../../assets/images/Al-Mashreq.png'
import EliteSuites from '../../assets/images/Elite-suites.png'
import FourSeasons from '../../assets/images/Four-seasons.png'
import yjet from '../../assets/images/ratings.svg'
import ratings from '../../assets/images/rating.svg'
import pool from '../../assets/images/pool.png'
import elements from '../../assets/images/elements.png'
import bar from '../../assets/images/bar.png'
import bigBuilding from '../../assets/images/bigBuilding.png'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import './Single-Hotel.scss'
export default function Home() {
  return (
    <div className='single-hotel'>
        <div className='filter-images'>
         <div className='hotel-filters-single'>
        <div className='search-flex'>
        <img className='logopatext-vertical' src={logoWithoutText}/>
        </div>
        <div className='search-flex'>
        <img  src={locationImage}/>
        <div className='search-text'>
        <p className='search-type' style={{marginBottom : "-15px"}}>Location</p>
          <p className='search-selected'>Madinah</p>
        </div>
        </div>
        <div className='search-flex'>
        <img  src={guestsImage}/>
        <div className='search-text'>
        <p className='search-type' style={{marginBottom : "-15px"}}>Travelers</p>
          <p className='search-selected'>2 Rooms, 4 Adults, 2 Children</p>
        </div>
        </div>
        <div className='search-flex'>
        <img  src={calendarImage}/>
        <div className='search-text'>
        <p className='search-type' style={{marginBottom : "-15px"}}>Check in & Check Out Dates</p>
          <p className='search-selected'>Nov 10,2022 - Nov 30, 2022</p>
        </div>
        </div>
        <div className='search-flex'>
        <img  src={hotelImage}/>
        <div className='search-text'>
        <p className='search-type' style={{marginBottom : "-15px"}}>Hotel Name</p>
          <p className='search-selected'>Al Madinah Harmony Hotel</p>
        </div>
        </div>
        <div className='search-flex'>
        <button type='button' className='button-filter'>Book Now</button>

        </div>
        </div>
        <div className='single-hotel-images'>
      
        <div className='big-image'>
            <div className='main-img'>
            <img src={bigBuilding}/>
            </div>
            <div className='small-images'>
            <img src={elements}/>
            <img src={bar}/>
            <img src={pool}/>
            </div>
        </div>
        </div>
        </div>
        <p className='nameof-hotel'>FOUR SEASONS HOTEL</p>
        <p className='hotel-description'>
        Located in Riyadh, Four Seasons Hotel Riyadh is a modern hotel situated in the famous Kingdom Tower. It offers an outdoor pool, a spa and an elegantly decorated accommodation. Guests can enjoy free WiFi throughout the property and in the hotel rooms. <br></br> <br></br> Boasting views of Riyadh, all accommodation at Four Seasons are fitted with modern furnishings. Each has a seating area with a flat-screen TV and an iPod docking station. The marble bathroom includes a large vanity and a shower.<br></br> <br></br>   Guests can enjoy an eclectic fine dining Middle Eastern experience at The Grill. <br></br> <br></br>  Guests have various activities to do on site such as tennis courts, 2 squash courts and a racquetball court. Relaxing massage treatments are available at the spa complimented by the sauna and steam room.<br></br> <br></br>  Four Seasons Hotel Riyadh is a 20-minute drive from Antique Souk and 30 minutes by car from King Khalid Airport. Luggage storage can be arranged by the 24-hour front desk.
        </p>
 


<table class="GeneratedTable">
  <thead>
    <tr>
      <th>Select</th>
      <th>Room Type</th>
      <th>Beds</th>
      <th>Includes</th>
      <th>Price</th>
      <th>Total Price</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>  <FormGroup>
      <FormControlLabel  control={<Checkbox defaultChecked />} label="" />
    </FormGroup></td>
      <td className='table-top'>  <p className='table-header'>Superior Twin Room</p> </td>
      <td className='table-mid'>2</td>
      <td className='table-top'> <p className='table-paragraph'> Bed Desk TV, Refrigirator, Telephone, Satellite channels Tea/Coffee maker, Iron interconnected room(s), Hairdryer, Carpeted Cable channels </p></td>
      <td className='table-mid'>150 €</td>
      <td>
        <div className='table-flex'>
            <p className='table-paragraph'>Room type:</p>
            <p className='table-header'>Superior Twin Room</p>
        </div>
        <div className='table-flex'>
            <p className='table-paragraph'>Beds:</p>
            <p className='table-header'>2</p>
        </div>
        <div className='table-flex'>
            <p className='table-paragraph'>Nights:</p>
            <p className='table-header'>20</p>
        </div>
        <div className='table-flex'>
            <p className='table-paragraph'>Price:</p>
            <p className='table-price'>3000$</p>
        </div>
      </td>
    </tr>
    <tr>
      <td>  <FormGroup>
      <FormControlLabel control={<Checkbox disabled />} label="" />
    </FormGroup></td>
      <td className='table-top'>  <p className='table-header'>Superior Twin Room</p> </td>
      <td className='table-mid'>2</td>
      <td className='table-top'> <p className='table-paragraph'> Bed Desk TV, Refrigirator, Telephone, Satellite channels Tea/Coffee maker, Iron interconnected room(s), Hairdryer, Carpeted Cable channels </p></td>
      <td className='table-mid'>150 €</td>
     
    </tr>
  </tbody>
</table>
    <p className='section-heading'>Related</p>
<div className='hotel-single-wrap'>
        <div className='hotel-single-page'>
            <img className='hotel-img' src={AlMashreq} />
       
            <p className='hotel-name'>AL MASHREQ BOUTIQUE HOTEL</p>
            <div className='hotel-info-wrap'> 
            <img src={locationImage} className='small-logo' />
            <p className='hotel-info'>Makkah</p>
            </div>
            <div className='hotel-info-wrap'> 
            <img src={hotelImage} className='small-logo' />
            <p className='hotel-info'>3 km away from kaaba</p>
            </div>
        </div>
        <div className='hotel-single-page'>
            <img className='hotel-img' src={AlMashreq} />
       
            <p className='hotel-name'>AL MASHREQ BOUTIQUE HOTEL</p>
            <div className='hotel-info-wrap'> 
            <img src={locationImage} className='small-logo' />
            <p className='hotel-info'>Makkah</p>
            </div>
            <div className='hotel-info-wrap'> 
            <img src={hotelImage} className='small-logo' />
            <p className='hotel-info'>3 km away from kaaba</p>
            </div>
        </div>
        <div className='hotel-single-page'>
            <img className='hotel-img' src={AlMashreq} />
       
            <p className='hotel-name'>AL MASHREQ BOUTIQUE HOTEL</p>
            <div className='hotel-info-wrap'> 
            <img src={locationImage} className='small-logo' />
            <p className='hotel-info'>Makkah</p>
            </div>
            <div className='hotel-info-wrap'> 
            <img src={hotelImage} className='small-logo' />
            <p className='hotel-info'>3 km away from kaaba</p>
            </div>
        </div>
       

    </div>

</div>
  )
}