import {
    SEARCH_HOTEL_REQUEST,
    SEARCH_HOTEL_SUCCESS,
    SEARCH_HOTEL_FAIL,
    SET_SEARCH_HOTEL_PARAMS,
    SEARCH_HOTEL_BY_ID_REQUEST,
    SEARCH_HOTEL_BY_ID_SUCCESS,
    SEARCH_HOTEL_BY_ID_FAIL,
    SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST,
    SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS,
    SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL,
    CREATE_RESERVATION_REQUEST,
    CREATE_RESERVATION_SUCCESS,
    CREATE_RESERVATION_FAIL,
    GET_ALL_HOTELS_REQUEST,
    GET_ALL_HOTELS_SUCCESS,
    GET_ALL_HOTELS_FAIL
} from '../constants/filterHotels';

export const searchHotelsReducer = (state = { data: null }, action) => {
    switch (action.type) {
        case SEARCH_HOTEL_REQUEST:
            return { loading: true, data: null }
        case SEARCH_HOTEL_SUCCESS:
            return { loading: false, data: action.payload }
        case SEARCH_HOTEL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

const initState = {
    location: '',
    beds: '',
    distance: '',
    hotelName: ''
}

export const setSearchHotelParamsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SEARCH_HOTEL_PARAMS:
            return { data: action.payload }
        default:
            return state
    }
}

export const searchHotelByIdReducer = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_HOTEL_BY_ID_REQUEST:
            return { loading: true }
        case SEARCH_HOTEL_BY_ID_SUCCESS:
            return { loading: false, payload: action.payload }
        case SEARCH_HOTEL_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const searchFreeRoomsByHotelId = (state = [], action) => {
    switch (action.type) {
        case SEARCH_FREEROOMS_BY_HOTEL_ID_REQUEST:
            return { loading: true }
        case SEARCH_FREEROOMS_BY_HOTEL_ID_SUCCESS:
            return { loading: false, payload: action.payload }
        case SEARCH_FREEROOMS_BY_HOTEL_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const createReservationReducer = (state = '', action) => {
    switch (action.type) {
        case CREATE_RESERVATION_REQUEST:
            return { loading: true }
        case CREATE_RESERVATION_SUCCESS:
            return { loading: false, payload: action.payload }
        case CREATE_RESERVATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getAllHotelsReducer = (state = null, action) => {
    switch (action.type) {
        case GET_ALL_HOTELS_REQUEST:
            return { loading: true }
        case GET_ALL_HOTELS_SUCCESS:
            return { loading: false, payload: action.payload }
        case GET_ALL_HOTELS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}