import axios from "axios";
const api_url = `https://api.wayumrah.com/`;
// const api_url = `http://127.0.0.1:8000/`;

const headers = {
  "Content-Type": "application/json",
  "x-api-key": "#. babo-ska-me-hi-knej-007",
};

class Service {
  searchHotels = (name,  location, distance) => {
    return axios.get(
      api_url +
        `api/v1/hotels?per_page=10000&name=${name}&location=${location}&distance_from_kaaba=${distance}`,
      { headers }
    );
  };

  getTest = (name) => {
    return name;
  };

  LoginUser = (email, password) => {
    return axios.post(api_url + `api/login`, { email, password });
  };

  sendVisa = (data) => {
    return axios.post(
      api_url + `api/v1/applications`, data,
      { headers }
    );
  };


}

export default Service;
