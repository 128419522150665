import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./Cart.scss";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useState } from "react";
import { Divider, Menu } from "@mui/material";
import arabianShield from '../../assets/images/Arabian-Shield-fb.png'
import Wala from '../../assets/images/wala.png'
import Tawuniya from '../../assets/images/tawuniya.png'
import Bupa from '../../assets/images/bupa.png'
import Medgulf from '../../assets/images/medgulf.png'
import gulfUnion from '../../assets/images/gulf-union.png'
import credit from '../../assets/images/credit.png'


export default function cart() {
  const [pass, passportType] = "";
  const [value, setValue] = "";

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.

      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  return (
  
<div className="singlecart-wrapper">
      <div className="singlecart" id="single-cart">
              <div className="cart-info">
              <p className="cart-header">Your order</p>
              <p className="cart-paragraph">Please fill out the infortmation to finish your payment</p>
              <TextField
                helperText=""
                className="input-visa"
                label="First Name"
                variant="outlined"
              />
                 <TextField
                helperText=""
                className="input-visa"
                label="Last Name"
                variant="outlined"
              />
                 <TextField
                helperText=""
                className="input-visa"
                label="Email Address"
                variant="outlined"
              />
              <TextField
                helperText=""
                type="number"
                className="input-visa"
                label="Telephone Number"
                variant="outlined"
              />
                   <div className="basic-visa-wrap">
              <TextField
                helperText=""
                className="input-visa"
                label="City"
                variant="outlined"
              />
              <TextField
                helperText=""
                className="input-visa"
                label="Country"
                variant="outlined"
              />
               <TextField
                helperText=""
                className="input-visa"
                label="Postal or ZIP Code"
                variant="outlined"
              />
              <TextField
                helperText=""
                className="input-visa"
                label="Address"
                variant="outlined"
              />
              </div>
              </div>
              <div className="cart-payment">
                <div className="order-info">
                  <p className="cart-header">Your Payment</p>
                  <div className="price">
                  <p className="cart-subheader">Your Order</p>
                  <p className="cart-subheader">Price</p>
                  </div>
                  <div className="price">
                  <p className="cart-paragraph">Visa Application for Saudi Arabia</p>
                  <p className="cart-paragraph">900 $</p>
                  </div>
                  <div className="price">
                  <p className="cart-subheader">TOTAL</p>
                  <p className="cart-paragraph green">900$</p>
                  </div>
                </div>
                <div className="credit-card">
                  <p className="cart-subheader">Credit Card (Stripe)</p>
                  <img src={credit} />
                  <TextField
                helperText=""
                type="number"
                className="card-number"
                label="Card Number"
                variant="outlined"
              />
                  <TextField
                helperText=""
                type="number"
                className="card-cvc"
                label="MM/YY"
                variant="outlined"
              />
                    <TextField
                helperText=""
                type="number"
                className="card-cvc"
                label="CVC"
                variant="outlined"
              />
                </div>
                <p className="cart-privacy">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <label>privacy policy.</label> </p>
                <button className="pay-button">Place Order</button>

              </div>

            </div>

            </div>
  );

}
