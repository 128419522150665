import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div>
        <div style={{backgroundColor: 'red'}}>About</div>
        <Link to="/">Home</Link>
    </div>
  )
}
