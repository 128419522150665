import { combineReducers } from "redux";
import { userReducer, userDataReducer } from "./user.reducer";
import { searchHotelsReducer, setSearchHotelParamsReducer, searchHotelByIdReducer, searchFreeRoomsByHotelId, createReservationReducer, getAllHotelsReducer } from './searchHotelsReducer';
import { getMyReservation, getMyVisaApplication } from "./reservationReducer";
const rootReducer = combineReducers({
    userData: userReducer,
    searchHotels: searchHotelsReducer,
    searchHotelParams: setSearchHotelParamsReducer,
    hotelById: searchHotelByIdReducer,
    freeRoomsByHotelId: searchFreeRoomsByHotelId,
    createReservation: createReservationReducer,
    user: userDataReducer,
    allHotels: getAllHotelsReducer,
    myReservation: getMyReservation,
    myVisaApplication: getMyVisaApplication
});

export { rootReducer };
