import React from 'react'
import Modal from '@mui/material/Modal';
import './ViewModal.scss';

const ViewModal = ({ children, open, close }) => {
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='modal-container'
        >
            <div>
                {children}
            </div>
        </Modal>
    )
}

export default ViewModal